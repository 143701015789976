import { useRef, useState } from 'react';
import { isNull } from 'lodash/fp';
import { Classes, Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import AdministrationRoleSelect from '../AdministrationRoleSelect';
import useSubject from '../../../../../services/api/portal/administration/hook/useSubject';
import ErrorMessage from './ErrorMessage';
import s from './index.module.scss';
import type { IRole } from 'services/api/portal/administration/api/types';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Tooltip } from 'opencosmos-ui';

interface IProps {
  onCancel: () => void;
  availableRoles: IRole[];
  assignRole?: (userId: string, roleIds: number[]) => void;
  currentId?: number;
}

const AdministrationUserSelect = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isFetching, user, getSubjectByIdOrEmail } = useSubject();

  const { translate } = useLocalisation();
  const [value, setValue] = useState('');

  const handleSearching = () => {
    if (!value) {
      props.onCancel();
      return;
    }
    void getSubjectByIdOrEmail(value);
  };

  const renderIcon = user ? (
    <img className={s.userIcon} src={user.picture} alt="avatar" />
  ) : (
    <Icon icon={IconNames.USER} iconSize={30} />
  );

  const showError = isNull(user);

  const notValidClass = showError ? Classes.INTENT_DANGER : '';

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <div className={s.userInfo}>
          {isFetching ? <Spinner size={30} /> : renderIcon}
          <Tooltip
            isDisabled={!user}
            isOpen={Boolean(user)}
            content={user ? user.name : ''}
          >
            <input
              ref={inputRef}
              className={[Classes.INPUT, notValidClass].join(' ')}
              placeholder="example@domain.com"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={handleSearching}
              onKeyDown={(e) => e.keyCode === 13 && handleSearching()}
            />
          </Tooltip>
        </div>
        {user && props.assignRole && (
          <AdministrationRoleSelect
            creationMode
            currentId={props.currentId}
            userId={user.id}
            assignRole={props.assignRole}
            availableRoles={props.availableRoles}
            onCancel={props.onCancel}
          />
        )}
      </div>
      {showError && (
        <ErrorMessage
          width={inputRef.current?.clientWidth ?? 0}
          message={translate(
            'userPage.assignedPermissions.errors.userDoesNotExist'
          )}
        />
      )}
    </div>
  );
};

export default AdministrationUserSelect;
