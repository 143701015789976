import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { Column } from 'ui/ColumnLayout/Column';
import { Header } from 'ui/Header';
import { ItemTabContainer } from 'ui/ItemContainer';
import { DateInput } from 'ui/DateInput';
import { Text } from 'ui/Text';
import type { useTaskingOverviewData } from './useTaskingOverviewData';
import { Loader } from 'ui/Loader';
import {
  Button,
  DialogTrigger,
  Icon,
  ListBoxItem,
  Select,
  Tooltip,
} from 'opencosmos-ui';
import { Dialog, Popover } from 'react-aria-components';
import type { RequestType } from '_api/tasking/types';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import PaginatedTaskingRequestsList from './PaginatedTaskingRequestsList';

const TaskingOverviewScreen = ({
  taskingOverviewData,
}: {
  taskingOverviewData: ReturnType<typeof useTaskingOverviewData>;
}) => {
  const {
    classifiedRequests,
    selectedRequest,
    handleTaskingRequestClick,
    loading,
    filter,
    handleMissionIdFilterClick,
    handleOrganisationIdFilterClick,
    handleTypeFilterClick,
    handleProjectIdFilterClick,
    allTaskingRequestsMetadata,
    getRequestsByStatus,
    shouldClearAllDateSegments,
    clearAllFilters,
    setShouldClearAllDateSegments,
    handleFromDateFilterClick,
    handleToDateFilterClick,
  } = taskingOverviewData;

  const { translate } = useLocalisation();

  return (
    <>
      <ItemTabContainer
        fullWidth={true}
        className="dark:bg-header-dark bg-header"
      >
        <Header margin={{ left: { value: 0 }, right: { value: 'auto' } }}>
          Requests
        </Header>

        <DateInput
          setValue={(val) => {
            handleFromDateFilterClick(val);
          }}
          value={filter.fromDate}
          title="From"
          shouldClearAllDateSegments={shouldClearAllDateSegments}
          setShouldClearAllDateSegments={setShouldClearAllDateSegments}
        />
        <DateInput
          setValue={(val) => {
            handleToDateFilterClick(val);
          }}
          value={filter.toDate}
          title="To"
          shouldClearAllDateSegments={shouldClearAllDateSegments}
          setShouldClearAllDateSegments={setShouldClearAllDateSegments}
        />
        <DialogTrigger>
          <Button data-testid="open-filters" isMinimal isTransparent>
            {translate('datacosmos.buttons.filters')}
          </Button>
          <Popover className={'bg-surface p-2'} placement="bottom right">
            <Dialog className={'flex flex-col gap-3'}>
              <Select
                label={translate('datacosmos.tasking.new.type')}
                selectedKey={filter?.type ?? 'ALL'}
                placeholder={translate(
                  'datacosmos.tasking.existing.filters.placeholder.type'
                )}
                name="type"
                testid="select-type"
                onSelectionChange={(val) => {
                  handleTypeFilterClick(val as string);
                }}
                fill
              >
                <ListBoxItem id={'ALL'} key={'ALL'}>
                  All
                </ListBoxItem>
                {['MANUAL', 'AUTOMATED'].map((type) => (
                  <ListBoxItem
                    id={type}
                    key={type}
                    data-testid={`type-option-${type}`}
                  >
                    {translate(
                      `datacosmos.tasking.existing.requestType.${
                        type as RequestType
                      }`
                    )}
                  </ListBoxItem>
                ))}
              </Select>

              <Select
                label={translate('datacosmos.catalogAndItems.project')}
                selectedKey={filter?.projectId ?? 'ALL'}
                placeholder={translate(
                  'datacosmos.tasking.existing.filters.placeholder.project'
                )}
                name="project"
                testid="select-project"
                onSelectionChange={(val) => {
                  handleProjectIdFilterClick(val as string);
                }}
                fill
              >
                <ListBoxItem id={'ALL'} key={'ALL'}>
                  All
                </ListBoxItem>
                {filter?.availableProjects?.map((project) => (
                  <ListBoxItem
                    id={project.id}
                    key={project.id}
                    data-testid={`project-option-${project.id}`}
                  >
                    {project.name}
                  </ListBoxItem>
                ))}
              </Select>

              <Select
                label="Mission"
                selectedKey={filter?.missionId ?? 'ALL'}
                placeholder={translate(
                  'datacosmos.tasking.existing.filters.placeholder.mission'
                )}
                name="mission"
                testid="select-mission"
                onSelectionChange={(val) => {
                  handleMissionIdFilterClick(val as string);
                }}
                fill
              >
                <ListBoxItem id={'ALL'} key={'ALL'}>
                  All
                </ListBoxItem>
                {filter?.availableMissionIds?.map((mission) => (
                  <ListBoxItem
                    id={mission.id}
                    key={mission.id}
                    data-testid={`missionId-option-${mission.id}`}
                  >
                    {mission.name}
                  </ListBoxItem>
                ))}
              </Select>

              <Select
                label="Organisation"
                selectedKey={filter?.organisationId ?? 'ALL'}
                placeholder={translate(
                  'datacosmos.tasking.existing.filters.placeholder.organisation'
                )}
                name="organisation"
                onSelectionChange={(val) =>
                  handleOrganisationIdFilterClick(val as string)
                }
                fill
              >
                <ListBoxItem id={'ALL'} key={'ALL'}>
                  All
                </ListBoxItem>
                {filter?.availableOrganisations?.map((org) => (
                  <ListBoxItem id={org.id} key={org.id}>
                    {org.name}
                  </ListBoxItem>
                ))}
              </Select>

              <Button
                onPress={() => {
                  clearAllFilters();
                }}
                fill
                intent="warning"
                icon="cross"
                iconPlacement="left"
                isMinimal
                size="sm"
                className={'my-1'}
              >
                {translate('datacosmos.filters.clearAll')}
              </Button>
            </Dialog>
          </Popover>
        </DialogTrigger>
      </ItemTabContainer>

      {loading ? (
        <Loader />
      ) : (
        <ColumnLayout
          cols={7}
          colRatio={[1, 1, 1, 1, 1, 1, 1]}
          horizontalGap={3}
        >
          <Column key={1} style={{ overflowY: 'auto', position: 'relative' }}>
            <Tooltip
              content={translate(
                'datacosmos.tasking.existing.requestStatus.PENDING.description'
              )}
            >
              <div className="flex items-center">
                <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.PENDING.title'
                  )}
                </Text>
                <Icon icon="Info" />
              </div>
            </Tooltip>

            <PaginatedTaskingRequestsList
              hasMore={Boolean(allTaskingRequestsMetadata?.PENDING?.remaining)}
              data-testid="pending-column"
              classifiedRequests={classifiedRequests?.pending}
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="PENDING"
              cursor={allTaskingRequestsMetadata?.PENDING?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={2} style={{ overflowY: 'auto', position: 'relative' }}>
            <Tooltip
              content={translate(
                'datacosmos.tasking.existing.requestStatus.PREPARED.description'
              )}
            >
              <div className="flex items-center">
                <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.PREPARED.title'
                  )}
                </Text>
                <Icon icon="Info" />
              </div>
            </Tooltip>

            <PaginatedTaskingRequestsList
              hasMore={Boolean(allTaskingRequestsMetadata?.PREPARED?.remaining)}
              classifiedRequests={classifiedRequests?.prepared}
              handleTaskingRequestClick={handleTaskingRequestClick}
              data-testid="prepared-column"
              getRequestsByStatus={getRequestsByStatus}
              status="PREPARED"
              cursor={allTaskingRequestsMetadata?.PREPARED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={3} style={{ overflowY: 'auto', position: 'relative' }}>
            <Tooltip
              content={translate(
                'datacosmos.tasking.existing.requestStatus.ACQUIRING.description'
              )}
            >
              <div className="flex items-center">
                <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.ACQUIRING.title'
                  )}
                </Text>
                <Icon icon="Info" />
              </div>
            </Tooltip>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(
                allTaskingRequestsMetadata?.ACQUIRING?.remaining
              )}
              classifiedRequests={classifiedRequests?.acquiring}
              data-testid="acquiring-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="ACQUIRING"
              cursor={allTaskingRequestsMetadata?.ACQUIRING?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={4} style={{ overflowY: 'auto', position: 'relative' }}>
            <Tooltip
              content={translate(
                'datacosmos.tasking.existing.requestStatus.FULFILLED.description'
              )}
            >
              <div className="flex items-center">
                <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.FULFILLED.title'
                  )}
                </Text>
                <Icon icon="Info" />
              </div>
            </Tooltip>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(
                allTaskingRequestsMetadata?.FULFILLED?.remaining
              )}
              classifiedRequests={classifiedRequests?.fulfilled}
              data-testid="fulfilled-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="FULFILLED"
              cursor={allTaskingRequestsMetadata?.FULFILLED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={5} style={{ overflowY: 'auto', position: 'relative' }}>
            <Tooltip
              content={translate(
                'datacosmos.tasking.existing.requestStatus.PARTIALLY_FULFILLED.description'
              )}
            >
              <div className="flex items-center">
                <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.PARTIALLY_FULFILLED.title'
                  )}
                </Text>
                <Icon icon="Info" />
              </div>
            </Tooltip>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(
                allTaskingRequestsMetadata?.PARTIALLY_FULFILLED?.remaining
              )}
              classifiedRequests={classifiedRequests?.partiallyFulfilled}
              data-testid="partially-fulfilled-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="PARTIALLY_FULFILLED"
              cursor={allTaskingRequestsMetadata?.PARTIALLY_FULFILLED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={6} style={{ overflowY: 'auto', position: 'relative' }}>
            <Tooltip
              content={translate(
                'datacosmos.tasking.existing.requestStatus.FAILED.description'
              )}
            >
              <div className="flex items-center">
                <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.FAILED.title'
                  )}
                </Text>
                <Icon icon="Info" />
              </div>
            </Tooltip>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(allTaskingRequestsMetadata?.FAILED?.remaining)}
              classifiedRequests={classifiedRequests?.failed}
              data-testid="failed-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="FAILED"
              cursor={allTaskingRequestsMetadata?.FAILED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={7} style={{ overflowY: 'auto' }}>
            <Tooltip
              content={translate(
                'datacosmos.tasking.existing.requestStatus.CANCELLED.description'
              )}
            >
              <div className="flex items-center">
                <Text className="sticky top-0 bg-surface dark:bg-surface-dark">
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.CANCELLED.title'
                  )}
                </Text>
                <Icon icon="Info" />
              </div>
            </Tooltip>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(
                allTaskingRequestsMetadata?.CANCELLED?.remaining
              )}
              classifiedRequests={classifiedRequests?.cancelled}
              data-testid="cancelled-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="CANCELLED"
              cursor={allTaskingRequestsMetadata?.CANCELLED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>
        </ColumnLayout>
      )}
    </>
  );
};

export default TaskingOverviewScreen;
