/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toaster } from 'toaster';
import { clientTranslate } from './hooks/useLocalisation';

export const loadAndConvertToBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () =>
      //@ts-expect-error
      resolve(reader.result?.toString()?.split('base64,')?.[1]);
    reader.onerror = (error) => reject(error);
  });

export const loadAndConvertToByteArray = (
  file: File
): Promise<ArrayBuffer | string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => res(reader.result!);
    reader.onerror = (error) => rej(error);
  });
};

export const isENVIPartiallyUploaded = (files: FileList) => {
  const hdrFile = Array.from(files).some((file) => file.name.endsWith('.hdr'));
  const datFile = Array.from(files).some((file) => file.name.endsWith('.dat'));
  if (!hdrFile && !datFile) {
    return false;
  }

  const isPartialENVI = (hdrFile && !datFile) || (datFile && !hdrFile);
  if (isPartialENVI) {
    toaster.show({
      intent: 'danger',
      message: clientTranslate(
        'datacosmos.fetchErrors.geopdf.postFile.enviError'
      ),
    });
  }
  return isPartialENVI;
};

export const isShpPartiallyUploaded = (files: FileList) => {
  const hasShp = Array.from(files).some((file) => file.name.endsWith('.shp'));
  const hasShx = Array.from(files).some((file) => file.name.endsWith('.shx'));
  const hasDbf = Array.from(files).some((file) => file.name.endsWith('.dbf'));

  if (!hasShp && !hasShx && !hasDbf) {
    return false;
  }
  const isPartialShp =
    (hasShp && (!hasShx || !hasDbf)) ||
    (hasShx && (!hasShp || !hasDbf)) ||
    (hasDbf && (!hasShp || !hasShx));
  if (isPartialShp) {
    toaster.show({
      intent: 'danger',
      message: clientTranslate(
        'datacosmos.fetchErrors.geopdf.postFile.shpError'
      ),
    });
  }
  return isPartialShp;
};

export const geoReferencedFileTypes =
  '.nc4, .nc, .dat, .hdr, image/tiff, .img, .kml, .kmz, .geojson, .shp, .dbf, .shx, .prj';

export const areAllUploadedFilesGeoReferenced = (files: FileList) => {
  const allowedExtensions = geoReferencedFileTypes
    .split(', ')
    .map((type) =>
      type.startsWith('image/') || type.startsWith('.')
        ? type.trim()
        : `.${type.trim()}`
    );

  const isGeoReferenced = Array.from(files).every((file) =>
    allowedExtensions.some((ext) => file.name.toLowerCase().endsWith(ext))
  );

  return isGeoReferenced;
};
