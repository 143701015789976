import React, { useEffect } from 'react';
import type { IFolder } from '../../../services/api/ops/cloudStorage';
import type { IModeProps, IMode } from '..';
import { IconNames } from '@blueprintjs/icons';
import s from './index.module.scss';
import DOMPurify from 'dompurify';
import { isNil } from 'lodash';
import { byteArrayToUTF8String } from 'utils/common/binnaryUtils';

const HTMLView: IMode<string> = ({
  fileDetails,
  renderedContent,
  setRenderedContent,
}: IModeProps<string>) => {
  useEffect(() => {
    if (fileDetails) {
      const { file } = fileDetails;

      if (!file) {
        return;
      }

      const data = byteArrayToUTF8String(file);
      const clean = DOMPurify.sanitize(data);

      setRenderedContent(clean);
    }
  }, [fileDetails, setRenderedContent]);

  return (
    <div
      className={'overflow-auto ' + s.markdownView}
      dangerouslySetInnerHTML={{ __html: renderedContent }}
    />
  );
};

HTMLView.isValid = (fileDetails: IFolder) => {
  if (fileDetails && !isNil(fileDetails.file)) {
    const data = byteArrayToUTF8String(fileDetails.file);
    if (typeof data !== 'undefined') {
      return true;
    }
  }
  return false;
};

HTMLView.isPreferred = (fileDetails: IFolder) => {
  return Boolean(fileDetails?.name.includes('.html'));
};

// Not in use because it is not editable
HTMLView.saveContent = (_: string) => new Uint8Array();
HTMLView.isEditable = false;

HTMLView.icon = IconNames.EDIT;

HTMLView.id = 'html-view';

export default HTMLView;
