import AdministrationLayout from '../../shared/components/AdministrationLayout/AdministrationLayout';
import useRoles from 'services/api/portal/administration/hook/useRoles';
import AdministrationRolesList from 'pages/portal/Administration/Roles/components/AdministrationRolesList';
import { PERMISSION_TYPE_LIST } from '_api/administration/permissions';
import { useLocalisation } from 'utils/hooks/useLocalisation';

const RolesAdministration = () => {
  const {
    availableRolesByType,
    createRole,
    updateRole,
    getRoleAttachmentsByRoleId,
    createRoleAttachment,
    deleteRoleAttachment,
  } = useRoles();
  const { translate, translateWithExplicitFallback } = useLocalisation();

  const getTitle = (type: string) => {
    return translateWithExplicitFallback(
      `rolesPage.perrmissionType.${type}` as unknown as TemplateStringsArray,
      `${(type as string).replace(/_/g, ' ')} Roles`
    );
  };

  return (
    <AdministrationLayout
      breadcrumbs={[{ text: translate('datacosmos.header.menu.rolesManager') }]}
    >
      {Object.values(PERMISSION_TYPE_LIST).map((type) => (
        <AdministrationRolesList
          key={type}
          title={getTitle(type)}
          rolesList={availableRolesByType(type)}
          getAvailableRolesByType={availableRolesByType}
          resourceType={type}
          updateRole={updateRole}
          createRole={createRole}
          getRoleAttachments={(role) => {
            const roleAttachmentResults = getRoleAttachmentsByRoleId(
              role.id.toString()
            );
            return roleAttachmentResults;
          }}
          addRolesAttachment={async (roleAttachment, parentRoleId) => {
            const addResult = await createRoleAttachment(
              roleAttachment,
              parentRoleId
            );
            return addResult;
          }}
          deleteRolesAttachment={async (role) => {
            const deleteResult = await deleteRoleAttachment(
              role.id,
              role.parentRoleId
            );
            return deleteResult;
          }}
        />
      ))}
    </AdministrationLayout>
  );
};

export default RolesAdministration;
