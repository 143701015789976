import type { IApplication } from 'datacosmos/types/applications';
import React from 'react';
import Tag from '_atoms/Tag/Tag';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import classNames from 'classnames';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import Spinner from 'opencosmos-ui/src/core/Spinner/Spinner';
import { Button, Icon, Tooltip } from 'opencosmos-ui';

type IProps = {
  app: IApplication;
  inputsRenderer: () => JSX.Element;
  setIsAppOpened: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: () => void;
  submitButtonLabel?: string | JSX.Element;
  toggleAppInstall: (app: IApplication) => void;
  isInstalled: boolean;
  loading?: boolean;
};

const OpenedAppCard = ({
  app,
  inputsRenderer,
  setIsAppOpened,
  handleSubmit,
  submitButtonLabel,
  toggleAppInstall,
  isInstalled,
  loading,
}: IProps) => {
  const { sendInfo } = useAnalytics();
  const { translate } = useLocalisation();

  const btnText = submitButtonLabel
    ? submitButtonLabel
    : 'Request subscription';
  return (
    <div
      onClick={() => {
        setIsAppOpened(false);
      }}
      className="flex gap-4 text-sm p-4 text-item-contrast dark:text-item-dark-contrast border-b-2 border-b-item-contrast last:border-b-0 last:border-b-transparent bg-surface dark:bg-surface-dark cursor-pointer"
    >
      {/* left card section */}
      <div className="flex flex-col flex-grow">
        {app.appScreenshotUrl && (
          <div className="h-24 w-24">
            <img
              src={app.appScreenshotUrl}
              alt={app.name + 'app screenshot'}
              className="object-contain bg-cover w-full h-full"
            />
          </div>
        )}
        {app.appScreenshotUrl && (
          <div className="h-full mt-2 flex flex-col gap-2 justify-start">
            {/* Tags go here */}
            {app.tags.map((t) => (
              <Tag text={t} key={t} color="#F2F0ED" />
            ))}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <span className="text-base">{app.name}</span>
          {app.isTraceableWorkflow && (
            <Tooltip
              content={translate(
                'datacosmos.applications.global.workflows.canBeTracedMessage'
              )}
            >
              <Icon icon="Clock" />
            </Tooltip>
          )}
        </div>
        <a
          className="flex gap-2 items-center group"
          href={app.provider.url}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          rel="noreferrer"
        >
          <img
            src={app.provider.icon_url}
            alt={`${app.provider.name} logo`}
            className={classNames('object-contain block h-10 w-10', {
              'w-full': app.provider.name === '',
            })}
          />

          {app.provider.name !== '' && (
            <span className="group-hover:text-accent-900">
              {app.provider.name}
            </span>
          )}
        </a>
        <p>{app.description}</p>
        <div>
          {app.frequencyOfData && <p>{app.frequencyOfData}</p>}
          {app.fee && <p>{app.fee}</p>}
          {app.leadTime && <p>Lead time: {app.leadTime}</p>}
          {app.additionalInfo && <p>{app.additionalInfo}</p>}
          {app.price && <div>ROM Price: {app.price}</div>}
        </div>

        <div className="flex w-full justify-between items-center">
          <Button
            icon={isInstalled ? 'InstallCrossed' : 'Download'}
            onPress={() => toggleAppInstall(app)}
            iconPlacement="left"
            isMinimal
          >
            {isInstalled
              ? translate('datacosmos.applications.deactivate')
              : translate('datacosmos.applications.activate')}
          </Button>
        </div>

        {/* inputs, additional info etc. */}

        <main>
          <div onClick={(e) => e.stopPropagation()}>{inputsRenderer()}</div>
        </main>

        <div>
          {handleSubmit && (
            <Button
              intent="primary"
              isDisabled={
                !Object.values(app.values).every(
                  (v) => v.value || v.value === 0
                ) ||
                !Object.values(app.values)
                  .filter((v) => {
                    if (!Array.isArray(v.value)) return false;
                    return v.value?.map !== undefined;
                  })
                  .every((v) => {
                    if (!Array.isArray(v.value)) return false;
                    return v.value.length > 0;
                  })
              }
              onPress={() => {
                sendInfo({
                  type: `Run application ${app.name}`,
                  action: 'Click',
                  item: 'Run application button',
                  module: 'DataCosmos',
                  additionalParams: {
                    application: app,
                  },
                });
                handleSubmit();
              }}
              className="h-10 p-2 w-full"
            >
              {loading ? (
                <div className="flex justify-center">
                  <Spinner size={'1rem'} />
                </div>
              ) : (
                btnText
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OpenedAppCard;
