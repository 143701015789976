import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import {
  Button,
  Label,
  PopoverProps,
  Select,
  SelectProps,
  ValidationResult,
  Text,
  FieldError,
  Popover,
  ListBox,
  ListBoxItem,
} from "react-aria-components";
import { Icon } from "../../icons/Icon";
import { tv } from "tailwind-variants";
import { Section } from "../Menu";

const select = tv({
  base: "flex items-center gap-1 text-start dark:bg-item-dark dark:text-item-dark-contrast dark:border-item-dark-contrast ",
  variants: {
    fill: {
      true: "w-full",
      false: "",
    },
  },
});

interface MySelectProps<T extends object>
  extends Omit<
    SelectProps<T>,
    "children" | "items" | "onSelectionChange" | "defaultSelectedKey"
  > {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items: { id: string; value: string }[];
  fill?: boolean;
  popoverProps?: PopoverProps;
  onSelectionChange?: (item: { id: string; value: string }) => void;
  defaultSelectedKeys?: string[];
  onRemove?: (item: { id: string; value: string }) => void;
  /**
   * If provided, the tree will be built based on this delimiter from the items' value
   */
  splitItemsDelimiter?: string;
  isDismissible?: boolean;
  hideRemoveButton?: boolean;
  onRemoveAll?: () => void;
  onSelectAll?: () => void;
  selectionIsValid?: (item: { id: string; value: string }) => boolean;
  autoAdjustHeight?: boolean;
  optionLabels?: { selectAll?: string; removeAll?: string };
}

const MultiSelect = <T extends Object>({
  label,
  description,
  errorMessage,
  items,
  fill,
  popoverProps,
  defaultSelectedKeys,
  splitItemsDelimiter,
  isDismissible,
  hideRemoveButton,
  onRemoveAll,
  onSelectAll,
  selectionIsValid,
  autoAdjustHeight,
  ...props
}: MySelectProps<T>) => {
  const selRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const [selectedItems, setSelectedItems] = React.useState<
    { id: string; value: string }[]
  >(items.filter((i) => defaultSelectedKeys?.some((k) => i.id === k)));
  const [isOpen, setIsOpen] = React.useState(false);

  const [expandedNodes, setExpandedNodes] = React.useState<TreeNode[]>([]);

  const itemsWithSelectStatus = useMemo(() => {
    return items.map((item) => {
      return {
        ...item,
        selected: selectedItems.some((i) => i.id === item.id),
      };
    });
  }, [items, selectedItems, expandedNodes]);

  const treeItems = useMemo(() => {
    return buildTree(
      itemsWithSelectStatus,
      selectedItems,
      expandedNodes,
      splitItemsDelimiter
    );
  }, [itemsWithSelectStatus, splitItemsDelimiter]);

  const addSelection = (key: string) => {
    const foundItem = items.find((item) => item.id === key);

    if (!foundItem) {
      return;
    }
    setSelectedItems((prev) => [...prev, foundItem]);
  };

  const removeSelection = (item: { id: string; value: string }) => {
    setSelectedItems((prev) => prev.filter((i) => i.id !== item.id));
    props.onRemove?.(item);
  };

  const toggleSelection = (item: { id: string; value: string }) => {
    if (selectedItems.some((i) => i.id === item.id)) {
      return removeSelection(item);
    }

    return addSelection(item.id);
  };

  const handleSelectionChange = (key: string) => {
    const foundItem = items.find((item) => item.id === key);

    if (!foundItem) {
      return;
    }

    toggleSelection(foundItem);
    props.onSelectionChange?.(foundItem);
  };

  const renderGroupedItems = () => {
    const renderNode = (node: TreeNode) => {
      if (node.children?.length === 0) {
        return (
          <div
            key={node.id}
            id={node.id}
            onClick={() => {
              handleSelectionChange(node.id);
            }}
            className={classNames({
              "bg-item-selected": node.selected,
            })}
            style={{
              paddingLeft: `${node.depth / 1.4}rem`,
            }}
          >
            {node.value}
          </div>
        );
      }

      return (
        <div>
          <button
            onClick={() => {
              node.isExpanded = !node.isExpanded;
              setExpandedNodes((prev) => {
                const isExpanded = prev.some((n) => n.id === node.id);
                return isExpanded
                  ? prev.filter((n) => n.id !== node.id)
                  : [...prev, node];
              });
            }}
            style={{
              paddingLeft: `${node.depth / 1.4}rem`,
            }}
            className={classNames("flex items-center gap-1 w-full", {
              "bg-item-selected": node.selected,
            })}
          >
            <Icon icon={node.isExpanded ? "ChevronDown" : "ChevronRight"} />
            <span style={{ fontWeight: "bold" }}>{node.value}</span>
          </button>

          <div className="pl-2">
            {node.isExpanded
              ? node.children.map((child) => renderNode(child))
              : null}
          </div>
        </div>
      );
    };

    return treeItems.map((node) => renderNode(node));
  };

  const renderUngroupedItems = () => {
    return (
      <ListBox items={itemsWithSelectStatus}>
        {(onRemoveAll || onSelectAll) && (
          <Section>
            {onSelectAll && (
              <ListBoxItem id={"selectAll"}>
                {props.optionLabels?.selectAll ?? "Select All"}
              </ListBoxItem>
            )}
            {onRemoveAll && (
              <ListBoxItem
                id={"removeAll"}
                className={
                  selectedItems.length > 0
                    ? ""
                    : "text-item-contrast-inactive dark:text-item-dark-contrast-inactive pointer-events-none cursor-not-allowed"
                }
              >
                {props.optionLabels?.removeAll ?? "Remove All"}
              </ListBoxItem>
            )}
          </Section>
        )}
        {itemsWithSelectStatus.map((item) => (
          <ListBoxItem
            key={item.id}
            id={item.id}
            className={classNames({
              "bg-item-selected": item.selected,
            })}
          >
            {item.value}
          </ListBoxItem>
        ))}
      </ListBox>
    );
  };

  useEffect(() => {
    setSelectedItems(
      items.filter((i) => defaultSelectedKeys?.some((k) => i.id === k))
    );
  }, [defaultSelectedKeys, items]);

  useEffect(() => {
    if (!isDismissible) {
      return;
    }

    const mousedown = (e: MouseEvent) => {
      if (popoverRef.current?.contains(e.target as HTMLElement)) {
        return;
      }
      setIsOpen(false);
    };

    window.addEventListener("mousedown", mousedown);

    return () => {
      window.removeEventListener("mousedown", mousedown);
    };
  }, []);

  return (
    <Select
      {...props}
      ref={selRef}
      isOpen={isOpen}
      onSelectionChange={(key) => {
        if (key === "removeAll") {
          onRemoveAll?.();
          setSelectedItems([]);
          return;
        }
        if (key === "selectAll") {
          onSelectAll?.();
          setSelectedItems(items);
          return;
        }
        const foundItem = items.find((item) => item.id === key);

        if (!foundItem || (selectionIsValid && !selectionIsValid(foundItem))) {
          return;
        }

        toggleSelection(foundItem);
        props.onSelectionChange?.(foundItem);
      }}
      className={(params) => {
        let p = {
          ...params,
          fill,
        };
        return select({
          ...p,
          class:
            typeof props.className === "function"
              ? props.className(p)
              : props.className,
        });
      }}
    >
      <Label className="whitespace-nowrap">{label}</Label>
      <Button
        className={classNames(
          "flex gap-1 items-center text-start",
          "flex items-center gap-1  bg-item dark:bg-item-dark dark:text-item-dark-contrast border dark:border-item-dark-contrast border-item-contrast",
          {
            "w-full": fill,
          }
        )}
        onPress={() => setIsOpen(!isOpen)}
      >
        <div
          className={classNames(
            "flex gap-1 flex-wrap min-w-[165px]",
            "p-1 hover:bg-item-hover dark:hover:bg-item-dark-hover focus-visible:bg-item-hover dark:focus-visible:bg-item-dark-hover focus-visible:outline-2 focus-visible:outline-neutral-600 focus-visible:outline",
            "overflow-x-auto scrollbar-invisible",
            {
              "w-full": fill,
            },
            { "h-8 max-h-8": !autoAdjustHeight },
            { "h-auto": autoAdjustHeight }
          )}
        >
          {selectedItems.length > 0 ? (
            selectedItems.map((item) => (
              <div className="text-xs flex gap-1 items-center rounded-md border bg-item-dark text-item-dark-contrast dark:bg-item-dark-contrast dark:text-item-dark border-item-contrast dark:border-item-dark-contrast p-[2px]">
                <span>{item.value}</span>
                {!hideRemoveButton && props.onRemove && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      removeSelection(item);
                    }}
                    className="flex items-center group hover:stroke-accent"
                  >
                    <Icon icon="cross" size={12} />
                  </button>
                )}
              </div>
            ))
          ) : (
            <span>{props.placeholder ?? "Select"}</span>
          )}
        </div>
        <Icon icon="ChevronDown" />
      </Button>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{errorMessage}</FieldError>
      <Popover
        ref={popoverRef}
        className={classNames(
          "bg-item dark:text-item-dark-contrast cursor-pointer dark:bg-item-dark max-h-96 overflow-auto",
          { "h-96": !autoAdjustHeight }
        )}
        style={{ width: selRef.current?.clientWidth }}
        {...popoverProps}
        isNonModal={true}
        shouldUpdatePosition={true}
      >
        {splitItemsDelimiter ? renderGroupedItems() : renderUngroupedItems()}
      </Popover>
    </Select>
  );
};

type TreeNode = {
  id: string;
  value: string;
  selected: boolean;
  children: TreeNode[];
  depth: number;
  isExpanded: boolean;
  fullPath: string;
};

const buildTree = (
  items: { id: string; value: string }[],
  selectedItems: { id: string; value: string }[],
  expandedNodes: TreeNode[],
  delimiter: string | undefined
): TreeNode[] => {
  if (!delimiter) {
    return [];
  }

  const root: TreeNode[] = [];

  items.forEach((item) => {
    const parts = item.value.split(".");
    let currentLevel = root;

    parts.forEach((part, index) => {
      const existingNode = currentLevel.find((node) => node.value === part);

      if (!existingNode) {
        const newNode: TreeNode = {
          id:
            index === parts.length - 1
              ? item.id
              : `${part}-${root.length}-${item.id}`,
          value: part,
          selected: selectedItems.some((i) =>
            includesExactMatch(i.value, parts.slice(0, index + 1).join("."))
          ),
          children: [],
          depth: index,
          isExpanded: expandedNodes.some(
            (n) => n.fullPath == parts.slice(0, index + 1).join(".")
          ),
          fullPath: parts.slice(0, index + 1).join("."),
        };

        currentLevel.push(newNode);
        currentLevel = newNode.children ?? [];
      } else {
        currentLevel = existingNode.children ?? [];
        existingNode.isExpanded = expandedNodes.some(
          (n) => n.fullPath === parts.slice(0, index + 1).join(".")
        );
      }
    });
  });

  return root;
};

function includesExactMatch(source: string, search: string): boolean {
  const regex = new RegExp(`\\b${search}\\b`);
  return regex.test(source);
}

export default MultiSelect;
