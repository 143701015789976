import {
  Label,
  ProgressBar,
  type ProgressBarProps,
} from "react-aria-components";

interface CustomeProgressBarProps extends ProgressBarProps {
  label?: string;
  type?: "linear" | "circular";
}

const CustomeProgressBar = ({
  label,
  type,
  ...props
}: CustomeProgressBarProps) => {
  const center = 16;
  const strokeWidth = 4;
  const r = 16 - strokeWidth;
  const c = 2 * r * Math.PI;
  return (
    <ProgressBar {...props}>
      {({ percentage, valueText }) => (
        <>
          <Label>{label}</Label>
          <span className="value">{valueText}</span>
          {type === "linear" ? (
            <div className="bar">
              <div className="fill" style={{ width: percentage + "%" }} />
            </div>
          ) : (
            <>
              <svg
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                strokeWidth={strokeWidth}
              >
                <circle
                  cx={center}
                  cy={center}
                  r={r - (strokeWidth / 2 - 0.25)}
                  stroke="currentColor"
                  strokeWidth={0.5}
                />
                <circle
                  cx={center}
                  cy={center}
                  r={r + (strokeWidth / 2 - 0.25)}
                  stroke="currentColor"
                  strokeWidth={0.5}
                />
                <circle
                  cx={center}
                  cy={center}
                  r={r}
                  stroke="currentColor"
                  strokeDasharray={`${c} ${c}`}
                  strokeDashoffset={c - (Number(percentage) / 100) * c}
                  strokeLinecap="round"
                  transform="rotate(-90 16 16)"
                />
              </svg>
            </>
          )}
        </>
      )}
    </ProgressBar>
  );
};

export default CustomeProgressBar;
