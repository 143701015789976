import { useQuery } from '@tanstack/react-query';
import {
  getRunLogs,
  getRuns,
  postRunRun,
  postStopRun,
} from '_api/runs/service';
import type { Run } from '_api/runs/types';
import Fuse from 'fuse.js';
import moment from 'moment';
import { deArrayify } from 'opencosmos-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useMission } from 'services/Missions';
import { toaster } from 'toaster';

export type RunWithDuration = Run & { duration: string };

export const EVENT_TYPES = [
  'All',
  'Manual',
  'Cron_job',
  'Data_ready',
  'Pass',
] as const;

const START_END_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

const appendDurationToRun = (run: Run): RunWithDuration => {
  const startTime = moment(run.start_time);
  const endTime = moment(run.end_time);
  const diff = moment.duration(endTime.diff(startTime)).asMilliseconds();

  const duration = isNaN(diff) ? 'N/A' : moment.utc(diff).format('m[m] s[s]');

  return {
    ...run,
    duration,
  };
};

export const useRunsData = () => {
  const { currentMissionId } = useMission();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const [cursor, setCursor] = useState<number>(
    Number(urlParams.get('page') ?? 1)
  );

  const {
    data: runs,
    refetch,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['runs', currentMissionId, cursor],
    queryFn: () => {
      return getRuns({
        params: {
          missionId: currentMissionId ?? '',
          cursor: cursor,
        },
      });
    },
    enabled: Boolean(currentMissionId),
  });

  const isFetchingRuns = isPending || isLoading || isFetching;

  const hasNextRunsPage = useMemo(
    () => runs?.meta && runs?.meta?.remaining > 0,
    [runs?.meta]
  );

  const hasPreviousRunsPage = useMemo(() => cursor > 1, [cursor]);

  const runsWithDurations = useMemo<RunWithDuration[]>(
    () => deArrayify((runs?.data ?? []).map(appendDurationToRun)),
    [runs?.data]
  );

  const fuseOptions = useMemo<Fuse.FuseOptions<RunWithDuration>>(
    () => ({
      threshold: 0.3,
    }),
    []
  );

  const [selectedRun, setSelectedRun] = useState<Run | undefined>();

  const [filteredRuns, setFilteredRuns] =
    useState<RunWithDuration[]>(runsWithDurations);

  const [nameSearchQuery, setNameSearchQuery] = useState<string | undefined>();
  const [startEndDateSearchQuery, setStartEndDateSearchQuery] = useState<{
    start: string | undefined;
    end: string | undefined;
  }>();
  const [eventsSearchQuery, setEventsSearchQuery] = useState<
    string | undefined
  >();

  const [isSendingEvent, setIsSendingEvent] = useState<boolean>(false);

  const {
    data: logs,
    isPending: isLogsPending,
    isLoading: isLogsLoading,
    isFetching: isLogsFetching,
    refetch: refetchLogs,
  } = useQuery({
    queryKey: ['logs', currentMissionId, selectedRun?.id],
    queryFn: () => {
      return getRunLogs({
        params: {
          missionId: currentMissionId ?? '',
          runId: selectedRun?.id ?? '',
        },
      });
    },
    // Don't cache logs
    gcTime: 0,
    enabled: Boolean(selectedRun),
  });

  const isFetchingLogs = isLogsPending || isLogsLoading || isLogsFetching;

  const toggleSelectedRun = useCallback(
    (run: Run) => {
      if (!run) {
        history.push(
          `/ops/mission/${currentMissionId ?? ''}/runs?page=${cursor}`
        );
        setSelectedRun(undefined);
        return;
      }

      history.push(
        `/ops/mission/${currentMissionId ?? ''}/runs?page=${cursor}&run_id=${
          run.id
        }`
      );
      setSelectedRun(run);
    },
    [currentMissionId, history, cursor]
  );
  const fetchNextRunsPage = async () => {
    setSelectedRun(undefined);
    if (!hasNextRunsPage) {
      return;
    }

    history.push(
      `/ops/mission/${currentMissionId ?? ''}/runs?page=${cursor + 1}`
    );

    setCursor((prev) => prev + 1);
    await refetch();
  };

  const fetchPreviousRunsPage = async () => {
    setSelectedRun(undefined);
    if (!hasPreviousRunsPage) {
      return;
    }

    history.push(
      `/ops/mission/${currentMissionId ?? ''}/runs?page=${cursor - 1}`
    );

    setCursor((prev) => prev - 1);
    await refetch();
  };

  const clearSearchQueries = () => {
    setNameSearchQuery(undefined);
    setStartEndDateSearchQuery(undefined);
    setEventsSearchQuery(undefined);
  };

  const refreshRuns = async () => {
    clearSearchQueries();
    await refetch();
  };

  const stopSelectedRun = async () => {
    if (!selectedRun) {
      return;
    }

    await postStopRun({
      params: {
        missionId: currentMissionId ?? '',
        runId: selectedRun.id,
      },
    });
  };

  const sendSelectedRunManualEvent = async () => {
    if (!selectedRun) {
      return;
    }

    setIsSendingEvent(true);
    const { success } = await postRunRun({
      params: {
        missionId: currentMissionId ?? '',
        scriptName: selectedRun.script_name,
      },
      body: selectedRun.event,
    });

    if (!success) {
      toaster.show({
        message: 'Failed to send event',
        intent: 'danger',
      });
    }

    setIsSendingEvent(false);
  };

  useEffect(() => {
    const runId = urlParams.get('run_id');
    if (!runId) {
      return;
    }

    const selected = runsWithDurations.find(
      (run) => run.id.toString() === runId
    );
    if (selected) {
      setSelectedRun(selected);
    }
  }, [runsWithDurations, urlParams]);

  useEffect(() => {
    setFilteredRuns(runsWithDurations);
  }, [runsWithDurations]);

  useEffect(() => {
    if (!runsWithDurations) {
      return;
    }

    if (!nameSearchQuery && !eventsSearchQuery && !startEndDateSearchQuery) {
      setFilteredRuns(runsWithDurations);
      return;
    }

    let results: RunWithDuration[] = runsWithDurations;

    if (nameSearchQuery) {
      const fuse = new Fuse(runsWithDurations, {
        ...fuseOptions,
        keys: ['script_name'],
      });

      const res = fuse.search(nameSearchQuery).map((result) => result) as {
        item: RunWithDuration;
      }[];

      results = res.map((r) => r.item ?? r);
    }

    if (startEndDateSearchQuery?.start && startEndDateSearchQuery?.end) {
      const formattedStart = moment(
        startEndDateSearchQuery.start,
        'YYYY-MM-DD'
      ).format(START_END_FORMAT);

      const formattedEnd = moment(
        startEndDateSearchQuery.end,
        'YYYY-MM-DD'
      ).format(START_END_FORMAT);

      results = results.filter((run) => {
        const end = run.end_time;
        const start = run.start_time;

        // If there's no end date, check if the start is between the chosen dates
        if (!end) {
          return moment(start).isBetween(formattedStart, formattedEnd);
        }

        return (
          moment(start).isSameOrAfter(formattedStart) &&
          moment(end).isSameOrBefore(formattedEnd)
        );
      });
    }
    if (eventsSearchQuery) {
      const fuse = new Fuse(results, {
        ...fuseOptions,
        keys: ['event.type'],
      });
      const res = fuse.search(eventsSearchQuery).map((result) => result) as {
        item: RunWithDuration;
      }[];

      results = res.map((r) => r.item ?? r);
    }

    setFilteredRuns(results);
  }, [
    eventsSearchQuery,
    fuseOptions,
    nameSearchQuery,
    runsWithDurations,
    startEndDateSearchQuery,
  ]);

  return {
    runs: filteredRuns,
    paginationData: runs?.meta,
    toggleSelectedRun,
    selectedRun,
    nameSearchQuery,
    setNameSearchQuery,
    startEndDateSearchQuery,
    setStartEndDateSearchQuery,
    eventsSearchQuery,
    setEventsSearchQuery,
    refreshRuns,
    isFetchingRuns,
    logs: logs?.data ?? [],
    isFetchingLogs,
    stopSelectedRun,
    sendSelectedRunManualEvent,
    isSendingEvent,
    refetchLogs,
    fetchNextRunsPage,
    fetchPreviousRunsPage,
    hasNextRunsPage,
    hasPreviousRunsPage,
  };
};
