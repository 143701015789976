/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import config from 'datacosmos/config';
import L from 'leaflet';
import type { Layer } from 'datacosmos/entities/layer';
import tilingApi from 'datacosmos/services/tilingApi';
import _ from 'lodash';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import type { Layer as LeafletLayer } from 'leaflet';
import type { VideoLayer } from 'datacosmos/entities/videoLayer';
import type { IStacItem } from 'datacosmos/types/stac-types';
import type { MapMarkerLayer } from 'datacosmos/entities/mapMarkerLayer';

import icon from 'leaflet/dist/images/marker-icon.png';
import type { PointLayer } from 'datacosmos/entities/pointLayer';
import COLORS from 'constants/colors';

// Default marker icon for Leaflet
const DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor: [16, 37],
  iconSize: [32, 37],
});

// Set the default marker icon for Leaflet
// This gets around the bug where the icon is not being shown in prod
// https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-264311098
L.Marker.prototype.options.icon = DefaultIcon;

export const stringToMapView = (string: string) => {
  const data = string.split(',');
  return { lng: data[0], lat: data[1], zoom: data[2] };
};

/**
 * Extended leaflet layer that has support for Open Cosmos layer IDs
 */
export type OCLeafletLayer = {
  OCid?: string | undefined;
  isPlaceholder?: boolean;
} & L.Layer;

/**
 * Returns the Leaflet layers that correspond to Open Cosmos layers.
 *
 * NOTE: In some cases the returned layer might be a "group layer" containing
 * more layers. In that case, use .getLayers() to obtain the children.
 */
export const getOCLayers = (map: L.Map | undefined) => {
  const layers: OCLeafletLayer[] = [];
  map?.eachLayer((l: OCLeafletLayer) => {
    if (l.OCid !== undefined) {
      // OpenCosmos layers have OCid
      layers.push(l);
    }
  });
  return layers;
};

export const getLayersFromArrayOfIds = (map: L.Map | null, ids: string[]) => {
  const layers: OCLeafletLayer[] = [];
  map?.eachLayer((l: OCLeafletLayer) => {
    if (l.OCid && ids.includes(l.OCid)) {
      layers.push(l);
    }
  });
  return layers;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const assignPaneToLayer = (layer: any, pane: string | undefined) => {
  if (layer.eachLayer !== undefined) {
    layer.eachLayer((l: LeafletLayer) => assignPaneToLayer(l, pane));
  } else {
    layer.options.pane = pane;
  }
};

export interface TileLayerOptions {
  opacity?: number;
  zIndex?: number;
  maxNativeZoom?: number;
}

export const newTileLayer = (url: string, options?: TileLayerOptions) => {
  return L.tileLayer(url, {
    opacity: options?.opacity ?? 1,
    zIndex: options?.zIndex ?? 1,
    maxZoom: config.map.maxZoom,
    maxNativeZoom: options?.maxNativeZoom ?? config.map.maxZoom,
  });
};

/**
 * Creates a new tiled Leaflet layer containing an image created from a band algebra expression.
 * Note: does not actually display the layer on the screen.
 * @param item the STAC item whose assets are combined to create the image
 * @param expression the band algebra expression for the image to show in this layer
 * @param rescale the scaling (min,max) to use for the colourmap on the layer
 * @param color the name of the colourmap to use for the layer
 * @returns a Leaflet suitable tile layer showing this band algebra image
 */
export const newLayerFromExpression = (
  item: IStacItem,
  expression: string,
  rescale: string,
  color: string,
  bandAlgebraType: string,
  rgbExpresion: string,
  rescaleFalse: string
) => {
  const formattedExpression =
    expression.split('::').length > 1
      ? expression.split('::')[1]
      : expression.split('::')[0];

  const expressionURL = tilingApi.generateBandAlgebraTilingURL(
    item,
    formattedExpression,
    rescale,
    color,
    bandAlgebraType,
    rgbExpresion,
    rescaleFalse
  );

  const isHighResPermissionGranted: boolean = item?.properties?.[
    'opencosmos:high_resolution_read_permission'
  ]! as boolean;

  if (!isHighResPermissionGranted) {
    return newTileLayer(expressionURL, {
      maxNativeZoom: config.map.maxNativeZoomBasedOnReadPermission,
    });
  }

  return expressionURL ? newTileLayer(expressionURL) : undefined;
};

export const newVideoLayer = (
  videoLayer: VideoLayer,
  options: L.VideoOverlayOptions = {}
) => {
  return L.videoOverlay(videoLayer.url, videoLayer.bounds, options);
};

export const newOutlineLayer = (
  item: IStacItem,
  options: L.GeoJSONOptions = {}
) => {
  return L.geoJSON(item.geometry, options);
};

const GEOJSON_LAYER_DEFAULT_OPTIONS: L.GeoJSONOptions = {
  pointToLayer: (_feature, latlng) => {
    return L.circleMarker(latlng, {
      radius: 8,
      fillColor: COLORS.MAIN_RED,
      color: COLORS.MAIN_BLACK,
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    });
  },
};

export const newGeoJSONLayer = (
  data: GeoJSON.GeoJSON,
  options: L.GeoJSONOptions = {}
) => {
  return L.geoJSON(data, { ...GEOJSON_LAYER_DEFAULT_OPTIONS, ...options });
};

export const newMapMarkerLayer = (
  mapMarkerLayer: MapMarkerLayer,
  options?: L.MarkerOptions
) => {
  return L.marker(
    L.latLng(mapMarkerLayer.position[1], mapMarkerLayer.position[0]),
    options
  );
};

export const newPointLayer = (
  pointLayer: PointLayer,
  options?: L.CircleMarkerOptions
) => {
  return L.circleMarker(
    L.latLng(pointLayer.position[1], pointLayer.position[0]),
    options
  );
};

export const newKMLLayer = (contents: string) => {
  const parser = new DOMParser();
  const kml = parser.parseFromString(contents, 'text/xml');
  const layer = new window.L.KML(kml) as OCLeafletLayer;
  return layer;
};

export const setPopupContent = async (
  mapLayer: OCLeafletLayer | Promise<OCLeafletLayer>,
  dcLayer: Layer
) => {
  const l = await mapLayer;
  const asGeoJSON = dcLayer as GeoJSONLayer<unknown>;

  if (_.isFunction((l as L.LayerGroup).eachLayer)) {
    (l as L.LayerGroup).eachLayer((gl) => {
      if (_.isFunction(asGeoJSON.getPopupContent)) {
        gl.setPopupContent(asGeoJSON.getPopupContent());
      }
    });
  }
};
