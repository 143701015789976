import { apiFetchHandler } from '_api/clientFetch';
import { hosts } from '_api/hosts';
import { toaster } from 'toaster';
import { clientTranslate } from 'utils/hooks/useLocalisation';

export type GetSTACItemPixelsParams = {
  collection: string;
  items: string;
  longitude: number;
  latitude: number;
};

export type PixelValueWithWavelength = { value: number; wavelength: number };

export type StacItemPixelData = {
  longitude: number;
  latitude: number;
  values: PixelValueWithWavelength[];
};

export const getSTACItemPixelsVsWavelength = apiFetchHandler<
  StacItemPixelData,
  GetSTACItemPixelsParams
>({
  host: hosts.data.v0,
  endpoint: ({ collection, items, latitude, longitude }) =>
    `/sampling/collections/${collection}/items/${items}/pixel?longitude=${longitude}&latitude=${latitude}`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.samplingPixels.cannotGet'
  ),
  errorDescription: (e) => e.join('; '),
  method: 'GET',
});

export type GetSingleImagePixelsParams = {
  longitude: number;
  latitude: number;
  imageUrl: string;
};

export type PixelData = {
  longitude: number;
  latitude: number;
  value: number[];
};

export const getSingleImagePixels = apiFetchHandler<
  PixelData,
  GetSingleImagePixelsParams
>({
  host: hosts.data.v0,
  endpoint: ({ imageUrl, latitude, longitude }) =>
    `/sampling/pixel?url=${encodeURIComponent(
      imageUrl
    )}&longitude=${longitude}&latitude=${latitude}`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.samplingPixels.cannotGet'
  ),
  errorDescription: (e) => e.join('; '),
  method: 'GET',
});

export type LinePixelDataParams = {
  url: string;
  start_longitude: number;
  start_latitude: number;
  end_longitude: number;
  end_latitude: number;
};

export const getLinePixelData = apiFetchHandler<
  PixelData[],
  LinePixelDataParams
>({
  host: hosts.data.v0,
  endpoint: ({
    url,
    start_longitude,
    start_latitude,
    end_longitude,
    end_latitude,
  }) =>
    `/sampling/line?url=${encodeURIComponent(
      url
    )}&start_longitude=${start_longitude}&start_latitude=${start_latitude}&end_longitude=${end_longitude}&end_latitude=${end_latitude}`,
  errorMessage: 'Could not get line pixel data',
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});

export type PixelSpectralSignature = {
  max: number;
  mean: number;
  min: number;
  percentile_25: number;
  percentile_75: number;
  wavelength: number;
};

export type PixelSpectralSignatureData = {
  values: PixelSpectralSignature[];
};

export type GetPixelSpectralSignaturesParams = {
  collection: string;
  items: string;
};

export type GetPixelSpectralSignaturesRequest = {
  aoi: GeoJSON.Geometry;
};

export const getPixelSpectralSignatures = apiFetchHandler<
  PixelSpectralSignatureData,
  GetPixelSpectralSignaturesParams,
  GetPixelSpectralSignaturesRequest
>({
  host: hosts.data.v0,
  endpoint: ({ collection, items }) =>
    `/sampling/collections/${collection}/items/${items}/aoi`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.samplingPixels.cannotGet'
  ),
  errorDescription: (e) => e.join('; '),
  method: 'POST',
});

export type PostSupervisedClassificationParams = {
  collection: string;
  items: string;
};

export type PostSupervisedClassificationBody = {
  project_id: string;
  categories: GeoJSON.FeatureCollection<GeoJSON.Point>;
  algorithm: string;
};

export type PostSupervisedClassificationData = {
  item_url: string;
};

export const postSupervisedClassification = apiFetchHandler<
  PostSupervisedClassificationData,
  PostSupervisedClassificationParams,
  PostSupervisedClassificationBody
>({
  host: hosts.data.v0,
  endpoint: ({ collection, items }) =>
    `/sampling/collections/${collection}/items/${items}/supervised_classification`,
  errorMessage: clientTranslate(
    'datacosmos.applications.supervisedClassification.errors.post'
  ),
  method: 'POST',
  errorDescription: (e) => e.join('; '),
  onSuccess: (data) => {
    const failed = !data?.item_url;
    toaster.show({
      message: clientTranslate(
        `datacosmos.applications.supervisedClassification.${
          failed ? 'errorResponse' : 'successResponse'
        }`
      ),
      intent: failed ? 'danger' : 'success',
    });
  },
});

export type PostUnsupervisedClassificationParams = {
  collection: string;
  items: string;
};

export type PostUnsupervisedClassificationBody = {
  project_id: string;
  classes: number;
  algorithm: string;
};

export type PostUnsupervisedClassificationData = {
  item_url: string;
};

export const postUnsupervisedClassification = apiFetchHandler<
  PostUnsupervisedClassificationData,
  PostUnsupervisedClassificationParams,
  PostUnsupervisedClassificationBody
>({
  host: hosts.data.v0,
  endpoint: ({ collection, items }) =>
    `/sampling/collections/${collection}/items/${items}/unsupervised_classification`,
  errorMessage: clientTranslate(
    'datacosmos.applications.unsupervisedClassification.errors.post'
  ),
  method: 'POST',
  errorDescription: (e) => e.join('; '),
  onSuccess: (data) => {
    const failed = !data?.item_url;
    toaster.show({
      message: clientTranslate(
        `datacosmos.applications.unsupervisedClassification.${
          failed ? 'errorResponse' : 'successResponse'
        }`
      ),
      intent: failed ? 'danger' : 'success',
    });
  },
});

export type ChangeDetectionType = 'vegetation' | 'water' | 'burn';

export type PostChangeDetectionParams = {
  collection: string;
  item: string;
};

export type PostChangeDetectionBody = {
  type: ChangeDetectionType;
  scale: number;
  target_item: string;
  target_collection: string;
  project_id: string;
};

export const submitChangeDetectionAlgo = apiFetchHandler<
  undefined,
  PostChangeDetectionParams,
  PostChangeDetectionBody
>({
  host: hosts.data.v0,
  endpoint: ({ collection, item }) =>
    `/sampling/collections/${collection}/items/${item}/change_detection`,
  errorMessage: clientTranslate(
    'datacosmos.applications.changeDetection.submit.error'
  ),
  errorDescription: (err) => err.join('; '),
  method: 'POST',
  onSuccess: (data) => {
    if (data) {
      toaster.show({
        message: clientTranslate(
          'datacosmos.applications.changeDetection.submit.success'
        ),
        intent: 'success',
      });
    }
  },
});

export type PostGeotransformImageParams = {
  collection: string;
  items: string;
};

export type PostGeotransformImageBody = {
  x_offset: number;
  y_offset: number;
};

export const postGeontransformImage = apiFetchHandler<
  undefined,
  PostGeotransformImageParams,
  PostGeotransformImageBody
>({
  host: hosts.data.v0,
  endpoint: ({ collection, items }) =>
    `/sampling/collections/${collection}/items/${items}/geotransform`,
  errorMessage: 'Could not geontransform image',
  method: 'POST',
  errorDescription: (e) => e.join('; '),
  onSuccess: () => {
    toaster.show({
      message: 'Image geotransformed successfully',
      intent: 'success',
    });
  },
});

interface GetSingleImageCovarianceMatrixParams {
  collectionId: string;
  itemId: string;
}

export type CovarianceMatrixData = {
  covariance_matrix: {
    metadata: {
      variables: string[];
      downsampling: string;
    };
    value: number[][];
  };
};

export const getSingleImageCovarianceMatrix = apiFetchHandler<
  CovarianceMatrixData,
  GetSingleImageCovarianceMatrixParams
>({
  host: hosts.data.v0,
  endpoint: ({ collectionId, itemId }) =>
    `/sampling/collections/${collectionId}/items/${itemId}/covariance`,
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.samplingMatrix.cannotGet'
  ),
  errorDescription: (e) => e.join(';'),
  method: 'GET',
});

export type PostScatterPlotParams = {
  collection: string;
  items: string;
  bands: string[];
};

export type PostScatterPlotBody = {
  bands: string[];
  downsample_factor?: number;
  bins?: number;
};

export type PostScatterPlotData = {
  metadata: {
    bands: string[];
    operation: 'frequencies';
    downsample_factor: number;
  };
  scatter_data: {
    [key: string]: number[];
  };
};

export const getScatterPlotData = apiFetchHandler<
  PostScatterPlotData,
  PostScatterPlotParams,
  PostScatterPlotBody
>({
  host: hosts.data.v0,
  endpoint: ({ collection, items, bands }) =>
    `/sampling/collections/${collection}/items/${items}/scatter_data?downsample_factor=18&${bands
      .map((b) => `bands=${b}`)
      .join('&')}`,
  errorMessage: 'Could not get scatter plot data',
  method: 'GET',
  errorDescription: (e) => e.join('; '),
});
