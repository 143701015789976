import MailToButton, { sendEmail } from '_organisms/MailToButton/MailToButton';
import { FEEDBACK_MAIL } from 'constants/contact';
import { Button } from 'opencosmos-ui';
import type { GroupedNavItems } from 'opencosmos-ui/src/navigation/Sidebar/types';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'services/auth/AuthWrapper';
import { UserProfileMenu } from 'ui/UserMenu';
import { getSelectedNavItem } from 'pages/shared/utils/nav/navItem';
import { ENABLE_SEND_FEEDBACK } from 'env';
import { clientTranslate } from 'utils/hooks/useLocalisation';

const usePortalNav = () => {
  const h = useHistory();
  const { user, isAllowedToReadRoles, isAllowedToReadUsersRoles } = useAuth();

  const portalNavMain: GroupedNavItems = useMemo(() => {
    return {
      ungrouped: {
        items: [
          {
            key: 'organisations',
            item: 'Organisations',
            onClick: () => h.push(`/portal/organisations`),
            icon: 'office',
            title: clientTranslate(
              'datacosmos.header.menu.tooltip.organisations'
            ),
          },
          {
            key: 'roles',
            item: 'Roles Manager',
            hidden: !isAllowedToReadRoles,
            onClick: () => h.push(`/portal/roles`),
            icon: 'Star',
            title: clientTranslate('datacosmos.header.menu.tooltip.roles'),
          },
          {
            key: 'users',
            item: 'Users',
            hidden: !isAllowedToReadUsersRoles,
            onClick: () => h.push(`/portal/users`),
            icon: 'UserGroup',
            title: clientTranslate('datacosmos.header.menu.tooltip.users'),
          },
        ],
      },
    };
  }, [h]);

  const portalNavFooter: GroupedNavItems = useMemo(() => {
    return {
      ungrouped: {
        items: [
          {
            key: 'help',
            item: 'Help',
            icon: 'Help',
            title: clientTranslate('datacosmos.header.menu.tooltip.help'),
            onClick: () => {
              window.location.href = '/help';
            },
          },
          {
            key: 'send-feedback',
            hidden: !ENABLE_SEND_FEEDBACK,
            title: clientTranslate(
              'datacosmos.header.menu.tooltip.send-feedback'
            ),
            item: (
              <MailToButton
                subject="Send feedback"
                fallbackEmail={FEEDBACK_MAIL}
                team={'datacosmos'}
                className="w-full flex items-center gap-1 !p-0 m-0 hover:text-item-dark"
                iconSize={24}
              />
            ),
            collapsedItem: (
              <Button
                icon="envelope"
                onPress={() => {
                  sendEmail('Send feedback', FEEDBACK_MAIL);
                }}
                size="lg"
                isTransparent={true}
                className={'border-none'}
              />
            ),
          },
          {
            key: 'home',
            item: 'Home',
            icon: 'home',
            title: clientTranslate('datacosmos.header.menu.tooltip.home'),
            onClick: () => {
              h.push('/');
            },
          },
          {
            divider: {
              above: true,
            },
            key: 'user-menu',
            item: (
              <div className="flex items-center gap-1 h-8">
                <div className="flex rounded-full w-[50px] h-12">
                  <UserProfileMenu />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm"> {user?.name}</span>
                  <span className="text-xs"> {user?.email}</span>
                </div>
              </div>
            ),
            collapsedItem: (
              <div className="flex rounded-full w-[50px] mt-1">
                <UserProfileMenu />
              </div>
            ),
            hideTooltip: true,
          },
        ],
      },
    };
  }, [h, user?.email, user?.name]);

  const selectedPortalItemKey = useMemo(
    () => getSelectedNavItem(portalNavMain, h.location.pathname),
    [h.location.pathname, portalNavMain]
  );

  return {
    portalNavMain,
    portalNavFooter,
    selectedPortalItemKey,
  };
};

export default usePortalNav;
