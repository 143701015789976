import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { Column } from 'ui/ColumnLayout/Column';
import { Navbar } from 'ui/Navbar';
import { Screen } from 'ui/Screen';
import { useTaskingOverviewData } from './useTaskingOverviewData';
import AddActivityModal from 'pages/datacosmos/tasking_overview/AddActivityModal';
import TaskingOverviewList from './TaskingOverviewList';
import TaskingOverviewPanel from './TaskingOverviewPanel';

const TaskingOverviewScreen = () => {
  const taskingOverviewData = useTaskingOverviewData();

  return (
    <Screen>
      <Navbar />
      <ColumnLayout
        cols={2}
        colRatio={[2, 1]}
        horizontalGap={10}
        style={{ overflowY: 'hidden' }}
      >
        <Column style={{ height: '94.5vh', overflowX: 'scroll' }}>
          <TaskingOverviewList taskingOverviewData={taskingOverviewData} />
        </Column>

        <Column
          style={{ overflow: 'auto' }}
          data-testid="request-details-column"
        >
          <TaskingOverviewPanel taskingOverviewData={taskingOverviewData} />
        </Column>
      </ColumnLayout>

      <AddActivityModal
        isOpen={taskingOverviewData.isAddActivityModalOpen}
        setIsOpen={taskingOverviewData.setIsAddActivityModalOpen}
        request={taskingOverviewData.selectedRequest}
        onSuccess={() => taskingOverviewData.refetchTaskingRequests()}
      />
    </Screen>
  );
};

export default TaskingOverviewScreen;
