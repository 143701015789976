import type { SensorId } from '_api/sensors/types';

export type ImagerMode = 'SNAPSHOT' | 'LINE_SCAN';

export type Imager = {
  name: SensorId;
  mode?: ImagerMode;
  compression_factor?: number;
  encoding?: number;
  band_setup?: { [key: string]: number };
};

export const ActivityStatusList = [
  'PENDING',
  'APPROVED',
  'CONFIRMED',
  'ACQUIRED',
  'SCHEDULED',
  'PROCESSING',
  'COMPLETED',
  'FAILED',
  'EXPIRED',
  'CANCELLED',
] as const;

export const CloudStatusList = ['UNDEFINED', 'CLOUDY', 'CLEAR'] as const;

export type CloudStatus = (typeof CloudStatusList)[number];

export type ActivityStatus = (typeof ActivityStatusList)[number];

export type ActivityStatusQueryFilter =
  | ActivityStatus
  | 'VALID'
  | 'COMMITTED'
  | 'AWAITING'
  | 'FINISHED'
  | 'INCOMPLETE'
  | 'UNSUCCESSFUL';

export const ActivityTypeList = ['IMAGE_ACQUISITION'] as const;

export type ActivityType = (typeof ActivityTypeList)[number];

export type ActivityPhysicalParameters = {
  geojson: GeoJSON.Feature<GeoJSON.Polygon>;
  midpoint: {
    date: string;
    sza_deg: number;
    oza_deg: number;
    sat_body_pos_m: [number, number, number];
    sat_body_vel_ms: [number, number, number];
  };
  ephemeris?: {
    type: string;
    attitude_law: string;
    data: {
      epoch: string;
      line1: string;
      line2: string;
    };
  };
};

export type ActivityStacParameter = {
  collection_id: string;
  item_id: string;
  processing_level: string;
  stac_link_time_event_processed: string;
};

export type ActivityOnboardProcessingParameter = {
  name: string;
  raw: string;
};

export type ActivitySessionParameter = {
  session_id?: number | undefined;
  predicted_session_id?: number | undefined | null;
};

export type ActivityParametersMetrics = {
  cloud_status?: CloudStatus;
  weather_forecast?: { forecast_cloud_cover_pc: number };
};

export type ActivityParameters = {
  platform?: {
    roll_angle?: number;
  };
  imager?: Imager;
  onboard_processing?: { [key: string]: unknown } | null;
  ground_processing?: { [key: string]: string } | null;
  physical?: {
    target: ActivityPhysicalParameters | undefined;
    latest: ActivityPhysicalParameters | undefined;
  };
  stac?: ActivityStacParameter[];
  session?: ActivitySessionParameter;
  metrics?: ActivityParametersMetrics;
};

export type Activity = {
  id: string;
  tasking_request_ids: string[];
  is_cancellable: boolean;
  start_date: string;
  end_date: string;
  mission_id: string;
  mission_name: string;
  status: ActivityStatus;
  price: string;
  type: ActivityType;
  operator_notes: string;
  priority: number;
  parameters: ActivityParameters;
  status_reason?: string;
};

// Used for displaying request notes in the table
export type ActivityWithRequestData = Activity & {
  request_note: string;
  organisation_name: string;
  region_name: string;
};
