import { Spinner } from '@blueprintjs/core';
import Button from '_molecules/Button/Button';
import LineChart from '_organisms/Charts/LineChart/LineChart';
import classNames from 'classnames';
import { downloadFilUrlAsExtension } from 'datacosmos/download/geojson';
import { usePixelMode } from 'datacosmos/stores/PixelModeProvider';
import React, { useRef, useState } from 'react';

import { json2csv } from 'json-2-csv';
import { screenshot } from 'utils/screenshot';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import OptionsDialog from '_organisms/OptionsDialog/OptionsDialog';
import { downloadFile } from 'utils/common/CommonUtils';

type Props = { rightMenuOpen: boolean };

const PixelSpectralSignatureModeChart = ({ rightMenuOpen }: Props) => {
  const {
    aoi,
    isSpectralSignaturesToggled,
    isFetchingSpectralSignatures,
    pixelSpectralSignaturesChartData,
    isDrawEnabled,
  } = usePixelMode();

  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);

  const chartRef = useRef<HTMLDivElement>(null);

  const { translate } = useLocalisation();

  const getLinesForPixelValues = () => {
    if (!pixelSpectralSignaturesChartData) {
      return [];
    }

    return [
      { color: 'orange', key: 'percentile_25' },
      { color: 'blue', key: 'percentile_75' },
      { color: 'green', key: 'min' },
      { color: 'red', key: 'max' },
      { color: 'purple', key: 'mean' },
    ];
  };

  const withAbsoluteContainer = (children: React.ReactNode) => (
    <div
      className={classNames(
        'dark:bg-surface-dark bg-surface dark:text-item-dark-contrast',
        'p-2 flex flex-col h-fit w-fit min-w-[400px] absolute right-[5rem] top-[60px]'
      )}
      style={{ right: rightMenuOpen ? '460px' : '5rem' }}
    >
      {children}
    </div>
  );

  if (!isDrawEnabled || !isSpectralSignaturesToggled) {
    return null;
  }

  if (isFetchingSpectralSignatures) {
    return withAbsoluteContainer(<Spinner />);
  }

  if (!aoi) {
    return withAbsoluteContainer(
      <div className="flex items-center w-full">
        {translate(
          'datacosmos.layers.pixelOperationsChart.spectralSignature.noAOI'
        )}
      </div>
    );
  }

  if (
    !pixelSpectralSignaturesChartData ||
    pixelSpectralSignaturesChartData.length === 0
  ) {
    return withAbsoluteContainer(
      <div className="flex items-center w-full">
        {translate(
          'datacosmos.layers.pixelOperationsChart.spectralSignature.noData'
        )}
      </div>
    );
  }

  return withAbsoluteContainer(
    <>
      <Button
        text={translate('datacosmos.layers.pixelOperationsChart.download')}
        className="relative mb-2"
        onPress={() => setIsDownloadModalOpen(true)}
      />
      <LineChart
        lines={getLinesForPixelValues()}
        data={pixelSpectralSignaturesChartData}
        lineDot={false}
        xAxisKey="wavelength"
        height={250}
        showLegend={false}
        showGrid={false}
        xAxisLabelFormatter={(d: string) => Number(d).toFixed(2) + 'μm'}
        xLabel={translate(
          'datacosmos.layers.pixelOperationsChart.wavelength.xAxisLabel'
        )}
        yLabel={translate('datacosmos.layers.pixelOperationsChart.yAxisLabel')}
        reference={chartRef}
      />
      <OptionsDialog
        options={[
          {
            name: 'PNG',
            onClick: async () => {
              if (!chartRef.current) {
                return;
              }
              await screenshot(chartRef.current, {
                mimeType: 'image/png',
              }).then((imgString) => {
                downloadFilUrlAsExtension(
                  imgString,
                  `pixel_spectral_signature_data_${new Date().toISOString()}`,
                  'png'
                );
              });
            },
          },
          {
            name: 'CSV',
            onClick: async () => {
              const csv = await json2csv(pixelSpectralSignaturesChartData);
              downloadFile(
                csv,
                `pixel_spectral_signature_data_${new Date().toISOString()}.csv`,
                'csv'
              );
            },
          },
        ]}
        title={translate(
          'datacosmos.layers.pixelOperationsChart.downloadDataAs'
        )}
        isOpen={isDownloadModalOpen}
        setIsOpen={setIsDownloadModalOpen}
      />
    </>
  );
};

export default PixelSpectralSignatureModeChart;
