import { useCallback } from 'react';
import { ResizableBox } from 'react-resizable';
import DefaultLayout from 'pages/ops/shared/components/DefaultLayout';
import { useMission } from 'services/Missions';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import {
  clearCommandCustomName,
  hideLeftPanel,
  hideRightPanel,
  selectSidePanelCommand,
  setExploringStorageName,
  showLeftPanel,
  showRightPanel,
} from 'actions/operate/actions';
import NavbarTimers from 'components/Timer/NavbarTimers';

import OperateHeader from './components/OperateHeader/OperateHeader';
import Library from './components/Library';
import Command from './components/Command/Command';
import Variables from './components/Parameters';
import s from './index.module.scss';
import { useHistory } from 'react-router';
import { useCurrentTerminalSession } from 'pages/ops/RTI/Operate/context/TerminalSessionProvider';
import { useRtiHotkeys } from 'pages/ops/RTI/Operate/hooks/hotkeys/useRtiHotkeys';
import { useRtiPanelDimensions } from 'pages/ops/RTI/Operate/hooks/useRtiPanelDimensions';
import { CommandMenu } from 'opencosmos-ui';
import ThemeProvider from 'datacosmos/stores/ThemeProvider';
import ActiveSessions from 'components/ActiveSessions';

function RTI() {
  const { currentMissionId } = useMission();
  const { dispatch, state } = useOperate();
  const { currentSession, ...command } = useCurrentTerminalSession();

  const { panelDimensions, setLibraryPanelWidth, setParametersPanelWidth } =
    useRtiPanelDimensions();

  const history = useHistory();

  const { rtiCommandList } = useRtiHotkeys();

  const setLeftSideHidden = (isHidden: boolean) => {
    if (isHidden) {
      dispatch(hideLeftPanel());
      return;
    }

    dispatch(showLeftPanel());
  };

  const setRightSideHidden = useCallback(
    (isHidden: boolean) => {
      if (isHidden) {
        dispatch(hideRightPanel());
        return;
      }

      dispatch(showRightPanel(state.rightPanelSelectedTab ?? 'parameters'));
    },
    [dispatch, state.rightPanelSelectedTab]
  );

  const setExploringStorageNameCallback = useCallback(
    (name: string) => {
      return dispatch(setExploringStorageName(name));
    },
    [dispatch]
  );

  const renderLeftPanel = () => {
    const library = (
      <Library
        isSideHidden={Boolean(state.isLeftSidePanelHidden)}
        handleSideHiding={setLeftSideHidden}
        availableCommands={command.availableCommands}
        sendUnarmedCommand={command.sendUnarmedCommand}
        cancelSentCommand={command.cancelCallBackByTimer}
        missionId={currentMissionId}
        exploringStorageName={state.exploringStorageName}
        areCommandsFetching={state.areCommandsFetching}
        setExploringStorageName={setExploringStorageNameCallback}
        handleSelectSidePanelCommand={(cmd) => {
          dispatch(clearCommandCustomName());
          setRightSideHidden(false);
          dispatch(selectSidePanelCommand(cmd));
        }}
        setRightSideHidden={setRightSideHidden}
      />
    );

    if (state.isLeftSidePanelHidden) {
      return (
        <div className={[s.close__side, s.right_side].join(' ')}>{library}</div>
      );
    }

    return (
      <div style={{ display: 'flex' }}>
        <ResizableBox
          height={Infinity}
          width={panelDimensions.libraryPanel}
          minConstraints={[340, Infinity]}
          resizeHandles={['e']}
          onResizeStop={(_, data) => {
            setLibraryPanelWidth(data.size.width);
          }}
        >
          {library}
        </ResizableBox>
      </div>
    );
  };

  const renderRightPanel = () => {
    const renderVariables = (
      <Variables
        isSideHidden={Boolean(state.isRightSidePanelHidden)}
        handleSideHiding={setRightSideHidden}
        updateWorkspace={command.updateWorkspace}
        commandHistory={command.commandHistory}
        replaceCommandNote={command.replaceCommandNote}
      />
    );

    if (state.isRightSidePanelHidden) {
      return (
        <div className={[s.close__side, s.left_side].join(' ')}>
          {renderVariables}
        </div>
      );
    }
    return (
      <div className="flex">
        <ResizableBox
          height={Infinity}
          width={panelDimensions.parametersPanel}
          minConstraints={[360, Infinity]}
          resizeHandles={['w']}
          onResizeStop={(_, data) => {
            setParametersPanelWidth(data.size.width);
          }}
        >
          {renderVariables}
        </ResizableBox>
      </div>
    );
  };

  const hideRightSide = useCallback(
    (value: boolean) => {
      setRightSideHidden(!value);
    },
    [setRightSideHidden]
  );

  // Width - 50px for the left sidebar
  const contentWidth = `calc(100vw - 50px)`;

  // TODO: import $pt-navbar-height from _custom_variables.scss
  const contentHeight = `calc(100vh - 50px)`;

  return (
    <ThemeProvider>
      <DefaultLayout
        shouldHideClock
        showMissionSelector
        light
        leftHeader={
          <OperateHeader
            session={currentSession}
            isConnected={Boolean(command.isConnected)}
          />
        }
        rightHeader={<ActiveSessions mission={currentMissionId} />}
        onMissionClick={() => {
          if (!currentMissionId) {
            return;
          }
          history.replace(`/ops/mission/${currentMissionId}/rti`);
        }}
        header={<NavbarTimers />}
      >
        <div className="flex flex-wrap">
          <CommandMenu
            commands={rtiCommandList}
            toggleHotkey="ALT + SHIFT + P"
            containerRef={'portal-root'}
          />
          <div
            style={{
              maxWidth: contentWidth,
              maxHeight: contentHeight,
              minHeight: contentHeight,
              width: '100%',
            }}
            className="select-none overflow-auto flex flex-row"
          >
            {renderLeftPanel()}
            <Command
              sendUnarmedCommand={command.sendUnarmedCommand}
              updateWorkspace={command.updateWorkspace}
              fireCommand={command.fireCommand}
              availableCommands={command.availableCommands}
              commandHistory={command.commandHistory}
              addCommandToHistory={command.addCommandToHistory}
              showParametersMenu={hideRightSide}
              handleGetHistoryCommandList={command.handleGetHistoryCommandList}
              noHistoryLeft={command.noHistoryLeft}
              messageLog={command.messageLog}
            />
            {renderRightPanel()}
          </div>
        </div>
      </DefaultLayout>
    </ThemeProvider>
  );
}

export default RTI;
