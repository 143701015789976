import { useEffect, useMemo, useRef, useState } from 'react';
import echarts from 'echarts';
import 'echarts-gl';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button, MultiSelect } from 'opencosmos-ui';
import type { PostScatterPlotData } from '_api/sampling/service';
import { getScatterPlotData } from '_api/sampling/service';
import { useLayerTools } from 'datacosmos/stores/LayerToolsProvider';
import { useActivePage } from '../Toolbar/ActivePageProvider';
import type { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import { isArray } from 'lodash';

export type ScatterPlotChartProps = {
  data: PostScatterPlotData | null;
  isMaximized?: boolean;
};

const parseScatterData = (scatterData: { [key: string]: number[] }) => {
  if (!scatterData) return {};
  const arrays = Object.values(scatterData);
  let maxValue = 0,
    minValue = 0;
  const result = arrays[0].map((x, i) => {
    const y = arrays[1][i];
    const z = arrays.at(3) ? arrays[2][i] : null;
    const freq = arrays.at(3) ? arrays[3][i] : arrays[2][i];
    maxValue = Math.max(freq, maxValue);
    minValue = Math.min(freq, minValue);
    return [x, y, z, freq].filter(
      (v) => v !== null && v !== undefined
    ) as number[];
  });
  return {
    data: result,
    maxValue,
    minValue,
  };
};

export const ScatterPlotChart = ({
  data,
  isMaximized,
}: ScatterPlotChartProps) => {
  const chartRef = useRef(null);
  const { translate } = useLocalisation();
  const { activePage, rightMenuOpen } = useActivePage();

  useEffect(() => {
    let chart: echarts.ECharts | null = null;
    if (chartRef.current && data) {
      chart = echarts.init(chartRef.current);
      const chartData = parseScatterData(data.scatter_data);
      const dimensions = data?.metadata?.bands?.length;
      chart.setOption({
        ...(dimensions === 3 && {
          tooltip: {
            formatter: (params) => {
              const p = isArray(params) ? params.at(0) : params;
              if (typeof p?.value === 'number') return '';
              return (
                `${data.metadata?.bands?.at(0) ?? ''}: ${
                  (p?.value?.[0] as number) ?? ''
                }<br />` +
                `${data.metadata?.bands?.at(1) ?? ''}: ${
                  (p?.value?.[1] as number) ?? ''
                }<br />` +
                `${data.metadata?.bands?.at(2) ?? ''}: ${
                  (p?.value?.[2] as number) ?? ''
                }<br />` +
                `${translate(
                  'datacosmos.catalogAndItems.metadata.yAxisLabel'
                )}: ${p?.value?.[3] as number} ${p?.marker ?? ''}`
              );
            },
          },
          grid3D: {
            viewControl: { minDistance: 10 },
            boxWidth: 100,
            boxHeight: 100,
          },
          xAxis3D: {
            name: data.metadata?.bands.at(0),
          },
          yAxis3D: {
            name: data.metadata?.bands.at(1),
          },
          zAxis3D: {
            name: data.metadata?.bands.at(2),
          },
        }),
        ...(dimensions === 2 && {
          xAxis: {
            name: data.metadata?.bands.at(0),
          },
          yAxis: {
            name: data.metadata?.bands.at(1),
          },
          tooltip: {
            formatter: (params) => {
              const p = isArray(params) ? params.at(0) : params;
              if (typeof p?.value === 'number') return '';
              return (
                `${data.metadata?.bands?.at(0) ?? ''}: ${
                  (p?.value?.[0] as number) ?? ''
                }<br />` +
                `${data.metadata?.bands?.at(1) ?? ''}: ${
                  (p?.value?.[1] as number) ?? ''
                }<br />` +
                `${translate(
                  'datacosmos.catalogAndItems.metadata.yAxisLabel'
                )}: ${(p?.value?.[2] as number) ?? ''} ${p?.marker ?? ''}`
              );
            },
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100,
            },
            {
              show: true,
              realtime: true,
              start: 0,
              end: 100,
            },
          ],
        }),
        visualMap: [
          {
            min: chartData?.minValue,
            max: (chartData?.maxValue ?? 0) / 2,
            calculable: true,
            realtime: false,
            dimension: dimensions, // frequencies is always the last key in data object
            inRange: {
              color: [
                '#1710c0',
                '#0b9df0',
                '#00fea8',
                '#00ff0d',
                '#f5f811',
                '#f09a09',
                '#fe0300',
              ],
            },
            right: 0,
            top: 'center',
          },
        ],
        toolbox: {
          top: 20,
          show: true,
          feature: {
            restore: {
              title: translate('datacosmos.layers.tools.scatter.resetGraph'),
            },
          },
        },
        series: [
          {
            type: dimensions === 3 ? 'scatter3D' : 'scatter',
            name: 'Frequencies',
            data: chartData?.data,
          },
        ],
      });
    }

    return () => {
      chart?.dispose();
    };
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (data && chartRef.current) {
        echarts.getInstanceByDom(chartRef.current)?.resize();
      }
    }, 10);
  }, [data, isMaximized, activePage, rightMenuOpen]);

  return (
    <div
      ref={chartRef}
      style={{ width: '100%', height: isMaximized ? '81dvh' : '400px' }}
      className="m-2"
    />
  );
};

type ToolProps = {
  layerItem: SingleBandSTACLayer;
};

type SelectedBand = { id: string; value: string };

export const ScatterPlotForm = ({ layerItem }: ToolProps) => {
  const [status, setStatus] = useState<'success' | 'loading' | 'error'>(
    'success'
  );
  const { translate } = useLocalisation();
  const { setData, selectedBands, setSelectedBands } = useLayerTools();

  const bands = useMemo(() => {
    return (
      Object.entries(layerItem?.item.assets ?? {})
        .filter(
          ([_id, value]) =>
            value.roles?.includes('data') &&
            Boolean(value['eo:bands']) &&
            Boolean(value.href)
        )
        .map(([id, value]) => ({ id, value: value.title ?? id })) ?? []
    );
  }, [layerItem]);

  const toggleBandSelection = (bandItem: SelectedBand) => {
    if (selectedBands?.find((band) => band.id === bandItem.id)) {
      setSelectedBands(selectedBands.filter((band) => band.id !== bandItem.id));
    } else {
      selectedBands.length < 3 &&
        setSelectedBands([...selectedBands, bandItem]);
    }
  };

  const handleGetScatterPlotData = async () => {
    if (!layerItem?.item?.collection) {
      return;
    }
    setStatus('loading');
    const { data, status: responseStatus } = await getScatterPlotData({
      params: {
        collection: layerItem?.item.collection,
        items: layerItem?.item.id,
        bands: selectedBands.map((band) => band.id),
      },
    });

    if (!data || responseStatus !== 200) {
      setStatus('error');
      return;
    }

    setData(data);
    setStatus('success');
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <MultiSelect
        fill
        name="bands"
        label={translate('datacosmos.layers.tools.buttons.bands')}
        className="flex flex-col justify-start items-start"
        items={bands}
        defaultSelectedKeys={selectedBands.map(({ id }) => id)}
        onSelectionChange={toggleBandSelection}
        selectionIsValid={(item: SelectedBand) =>
          selectedBands.some((b) => b.id === item.id) ||
          selectedBands.length < 3
        }
        onRemove={toggleBandSelection}
        onRemoveAll={() => setSelectedBands([])}
        optionLabels={{
          removeAll: translate('datacosmos.layers.tools.removeAll'),
        }}
        placeholder={translate('datacosmos.layers.tools.selectPlaceholder')}
      />
      <Button
        onPress={handleGetScatterPlotData}
        className={`w-full ${status === 'loading' ? 'cursor-not-allowed' : ''}`}
        loading={status === 'loading'}
        intent="primary"
      >
        {translate('datacosmos.layers.tools.buttons.submit')}
      </Button>
      {status === 'error' && (
        <span className="text-warning">
          {translate('datacosmos.layers.tools.errors.request')}
        </span>
      )}
    </div>
  );
};
