import React from "react";
import { NavItem } from "./types";
import classNames from "classnames";
import Icon from "../../icons/Icon";
import Divider from "./Divider";
import { Tooltip } from "../../core";
import { formatGroupTitle } from "./helpers";
import { Link } from "react-router-dom";

type Props = {
  item: NavItem;
  isCollapsed?: boolean;
  isSelected?: boolean;
};

const LinkContent = ({ isCollapsed, item }: Props) => {
  return (
    <Tooltip
      content={
        isCollapsed && typeof item.item !== "string"
          ? formatGroupTitle(item.key)
          : item.item
      }
      isDisabled={!isCollapsed || item.hideTooltip}
      placement="right"
      offset={10}
    >
      <div className="flex items-center gap-2">
        {item.icon && !item.collapsedItem && (
          <Icon icon={item.icon} size={24} />
        )}
        {isCollapsed ? item.collapsedItem : item.item}
      </div>
    </Tooltip>
  );
};

const HrefLink = ({
  item,
  isCollapsed,
  isSelected,
  children,
}: Props & { children: React.ReactNode }) => {
  return (
    <Link
      to={item.href ?? "#"}
      key={item.key}
      onClick={() => item.onClick?.(item.key)}
      className={classNames(
        "px-4 flex items-center gap-2 h-12 hover:bg-item-hover dark:hover:bg-item-dark-hover cursor-pointer text-sm",
        {
          "bg-item-selected dark:bg-item-dark-selected": isSelected,
          "justify-center h-[50px]": isCollapsed,
        }
      )}
    >
      {children}
    </Link>
  );
};

const DivItem = ({
  item,
  isCollapsed,
  isSelected,
  children,
}: Props & { children: React.ReactNode }) => {
  return (
    <div
      key={item.key}
      onClick={() => item.onClick?.(item.key)}
      className={classNames(
        "px-4 flex items-center gap-2 h-12 hover:bg-item-hover dark:hover:bg-item-dark-hover cursor-pointer text-sm",
        {
          "bg-item-selected dark:bg-item-dark-selected": isSelected,
          "justify-center h-[50px]": isCollapsed,
        }
      )}
    >
      {children}
    </div>
  );
};

const SidebarItem = ({ item, isCollapsed, isSelected }: Props) => {
  if (item.href) {
    return (
      <>
        {item.divider?.above && <Divider />}
        <HrefLink item={item} isCollapsed={isCollapsed} isSelected={isSelected}>
          <LinkContent isCollapsed={isCollapsed} item={item} />
        </HrefLink>
        {item.divider?.below && <Divider />}
      </>
    );
  }

  return (
    <>
      {item.divider?.above && <Divider />}
      <DivItem item={item} isCollapsed={isCollapsed} isSelected={isSelected}>
        <LinkContent isCollapsed={isCollapsed} item={item} />
      </DivItem>
      {item.divider?.below && <Divider />}
    </>
  );
};

export default SidebarItem;
