import type { ICommandPileItem } from 'services/api/ops/realtimeTerminalSession';
import type { ICommandHistory } from 'pages/ops/RTI/Operate/hooks/commandSpace/useCommandHistory';
import type { IOperateActionTypes } from 'constants/ops/rti/oparate/actionTypes';
import {
  HISTORY_FOCUS_LAST_SENT_COMMAND,
  HISTORY_UPDATE_COMPLETED_STATUS,
  SET_CURRENTLY_EDITING_COMMAND_PAYLOAD,
  ARMED_COMMAND_PREVIEW_CLOSE,
  ARMED_COMMAND_PREVIEW_OPEN,
  CLEAR_COMMAND_CUSTOM_NAME,
  CLEAR_CURRENT_PAYLOAD,
  COMMAND_CHANGE_DONE,
  COPY_COMMAND_TO_EDITOR,
  FINISH_HISTORY_COMMAND_LIST,
  HISTORY_COMMAND_CLOSE,
  HISTORY_COMMAND_OPEN,
  HISTORY_SET_CURRENT_RESPONSE,
  LAST_HISTORY_COMMAND_REACHED,
  PAST_COMMAND_FROM_CLIPBOARD,
  PILE_COMMAND_REMOVE,
  PILE_ITEM_CLOSE,
  PILE_ITEM_OPEN,
  REQUEST_HISTORY_COMMAND_LIST,
  SAVE_CLOUD_STORAGE_PATH,
  SAVE_SATELLITE_STORAGE_PATH,
  SELECT_COMMAND_FROM_SUGGESTION,
  SELECT_SIDE_PANEL_COMMAND,
  SET_COMMAND_CUSTOM_NAME,
  SET_COMMAND_PAYLOAD,
  SET_EXPLORING_STORAGE_NAME,
  SET_HISTORY_COMMAND_TYPE,
  SET_SEND_COMMAND_DISABLED,
  SET_TERMINAL_WORKSPACE,
  REQUEST_AVAILABLE_COMMANDS,
  FINISH_AVAILABLE_COMMANDS,
  SET_PROCEDURE_NAME,
  REORDER_COMMAND_PILE,
  SELECTED_PILE_ITEM,
  TOGGLE_LEFT_PANEL,
  TOGGLE_RIGHT_PANEL,
  TOGGLE_COMMAND_HISTORY_COLLAPSE,
  SET_RIGHT_SIDE_SELECTED_TAB,
} from 'constants/ops/rti/oparate/actionTypes';
import { arrayMove } from 'utils/common/CommonUtils';
import type { CommandDefinition } from '_api/telecommands/types';

export interface IOperateState {
  procedureName?: string;
  armedCommand?: ICommandPileItem;
  // Stores command selected either from the left sidemenu, command pile or armed commands list
  selectedCommand?: CommandDefinition;
  frozenPile?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  commandPayload?: any;
  commandCustomName?: string;
  isSendCommandDisabled?: boolean;
  // List of items located in the command pile
  commandPileWorkingSpace?: ICommandPileItem[];
  // Currently unused outside this file. Should probably be renamed to current command (the command we currently edit)
  currentlyEditingCommand?: CommandDefinition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentylEditingCommandPayload?: any;
  isCommandPreview?: boolean;
  // Represents command selected from command pile. Fills right sidemenu with item's payload.
  selectedPileItem?: ICommandPileItem;
  // represents the group of commands selected from the command pile
  selectedMultiplePileItems?: ICommandPileItem[];
  currentHistoryResponse?: ICommandHistory;
  isHistoryFetching?: boolean;
  areCommandsFetching?: boolean;
  historySelectedCommand?: CommandDefinition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  historyCommandPayload?: any;
  historyCommandType?: string;
  lastHistoryCommandReached?: boolean;
  isCommandChanging?: boolean;
  exploringStorageName?: string;
  cloudStorageSavedPath?: string;
  satelliteStorageSavedPath?: string;
  isLeftSidePanelHidden?: boolean;
  isRightSidePanelHidden?: boolean;
  isCommandHistoryCollapsed?: boolean;
  shouldFocusLastSentCommand?: boolean;
  rightPanelSelectedTab?: string;
}

export default (
  state: IOperateState,
  action: IOperateActionTypes
): IOperateState => {
  switch (action.type) {
    case SET_PROCEDURE_NAME: {
      const { procedureName } = action.payload;
      return { ...state, procedureName };
    }
    case SET_TERMINAL_WORKSPACE: {
      const { armedCommand, commandPileWorkingSpace, frozenPile } =
        action.payload;
      return { ...state, armedCommand, commandPileWorkingSpace, frozenPile };
    }
    case SELECT_SIDE_PANEL_COMMAND: {
      const { command } = action.payload;

      const isCommandChanging =
        Boolean(state.historySelectedCommand) ||
        state.selectedCommand !== command;

      return {
        ...state,
        isCommandChanging,
        selectedCommand: command,
        historySelectedCommand: undefined,
        historyCommandPayload: undefined,
        commandPayload: {},
        selectedPileItem: undefined,
        currentHistoryResponse: undefined,
        currentlyEditingCommand: command,
        isCommandPreview: false,
        isSendCommandDisabled: false,
        selectedMultiplePileItems: undefined,
      };
    }
    case ARMED_COMMAND_PREVIEW_OPEN: {
      const { armedCommand, command } = action.payload;

      return {
        ...state,
        currentHistoryResponse: undefined,
        selectedPileItem: armedCommand,
        isSendCommandDisabled: true,
        isCommandPreview: true,
        selectedCommand: command,
        historySelectedCommand: undefined,
        historyCommandPayload: undefined,
        commandPayload: armedCommand.payload,
        commandCustomName: armedCommand.customName,
      };
    }
    case ARMED_COMMAND_PREVIEW_CLOSE: {
      return {
        ...state,
        currentHistoryResponse: undefined,
        selectedPileItem: undefined,
        isSendCommandDisabled: false,
        isCommandPreview: false,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        commandPayload: state.currentylEditingCommandPayload,
        commandCustomName: undefined,
        selectedCommand: state.currentlyEditingCommand,
      };
    }
    case CLEAR_CURRENT_PAYLOAD: {
      return { ...state, commandPayload: {} };
    }

    case SET_CURRENTLY_EDITING_COMMAND_PAYLOAD: {
      return {
        ...state,
        currentylEditingCommandPayload:
          action.payload.currentylEditingCommandPayload,
      };
    }
    case SET_COMMAND_PAYLOAD: {
      return {
        ...state,
        commandPayload: action.payload.commandPayload,
      };
    }
    case CLEAR_COMMAND_CUSTOM_NAME: {
      return { ...state, commandCustomName: undefined };
    }
    case SET_COMMAND_CUSTOM_NAME: {
      return {
        ...state,
        commandCustomName: action.payload.commandCustomName,
      };
    }
    case COPY_COMMAND_TO_EDITOR: {
      const { selectedCommand, commandPayload, commandCustomName } =
        action.payload;

      const isCommandChanging = state.historySelectedCommand
        ? state.selectedCommand === selectedCommand
        : state.selectedCommand !== selectedCommand;

      return {
        ...state,
        selectedCommand,
        commandPayload,
        isCommandChanging,
        commandCustomName,
        selectedPileItem: undefined,
        isSendCommandDisabled: false,
        isCommandPreview: false,
        currentHistoryResponse: undefined,
        historySelectedCommand: undefined,
        historyCommandPayload: undefined,
      };
    }
    case PILE_COMMAND_REMOVE: {
      return {
        ...state,
        commandPayload: {},
        isSendCommandDisabled: false,
        selectedPileItem: undefined,
      };
    }
    case PILE_ITEM_OPEN: {
      const { pileItem, selectedCommand } = action.payload;
      return {
        ...state,
        selectedCommand,
        commandPayload: pileItem.payload,
        commandCustomName: pileItem.customName,
        isCommandChanging: true,
        isCommandPreview: false,
        currentHistoryResponse: undefined,
        historySelectedCommand: undefined,
        historyCommandPayload: undefined,
        isSendCommandDisabled: true,
        selectedPileItem: pileItem,
        selectedMultiplePileItems: undefined,
      };
    }
    case SELECTED_PILE_ITEM: {
      const { pileItemsSelected } = action.payload;
      return {
        ...state,
        selectedMultiplePileItems: pileItemsSelected,
      };
    }
    case PILE_ITEM_CLOSE: {
      return {
        ...state,
        isCommandChanging: false,
        selectedPileItem: undefined,
        isSendCommandDisabled: false,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        commandPayload: state.currentylEditingCommandPayload,
        commandCustomName: undefined,
        isCommandPreview: false,
        currentHistoryResponse: undefined,
        selectedCommand: state.currentlyEditingCommand,
      };
    }
    case HISTORY_COMMAND_OPEN: {
      const { command, history } = action.payload;

      return {
        ...state,
        historySelectedCommand: command,
        historyCommandPayload: history?.payload,
        commandCustomName: history?.customCommandName,
        isCommandPreview: true,
        isCommandChanging: true,
        isSendCommandDisabled: true,
        selectedPileItem: undefined,
        currentHistoryResponse: history,
      };
    }
    case HISTORY_UPDATE_COMPLETED_STATUS: {
      const { history } = action.payload;

      return {
        ...state,
        currentHistoryResponse: history,
      };
    }
    case HISTORY_SET_CURRENT_RESPONSE: {
      const { history } = action.payload;

      return {
        ...state,
        currentHistoryResponse: history,
      };
    }
    case HISTORY_FOCUS_LAST_SENT_COMMAND: {
      const { shouldFocusLastSentCommand: focusLastSentCommand } =
        action.payload;

      return {
        ...state,
        shouldFocusLastSentCommand: focusLastSentCommand,
      };
    }
    case HISTORY_COMMAND_CLOSE: {
      return {
        ...state,
        isCommandChanging: true,
        historySelectedCommand: undefined,
        currentHistoryResponse: undefined,
        historyCommandPayload: undefined,
        isCommandPreview: false,
        isSendCommandDisabled: false,
      };
    }
    case REQUEST_HISTORY_COMMAND_LIST: {
      return {
        ...state,
        isHistoryFetching: true,
      };
    }
    case FINISH_HISTORY_COMMAND_LIST: {
      return {
        ...state,
        isHistoryFetching: false,
      };
    }
    case REQUEST_AVAILABLE_COMMANDS: {
      return {
        ...state,
        areCommandsFetching: true,
      };
    }
    case FINISH_AVAILABLE_COMMANDS: {
      return {
        ...state,
        areCommandsFetching: false,
      };
    }
    case SET_HISTORY_COMMAND_TYPE: {
      let clearHistory;
      if (state.historySelectedCommand) {
        clearHistory = {
          isCommandChanging: true,
          historySelectedCommand: undefined,
          currentHistoryResponse: undefined,
          historyCommandPayload: undefined,
          isCommandPreview: false,
          isSendCommandDisabled: false,
        };
      }

      return {
        ...state,
        ...clearHistory,
        historyCommandType: action.payload.type,
      };
    }
    case LAST_HISTORY_COMMAND_REACHED: {
      return {
        ...state,
        lastHistoryCommandReached: true,
      };
    }
    case SELECT_COMMAND_FROM_SUGGESTION: {
      const { selectedCommand } = action.payload;

      const isCommandChanging = state.selectedCommand !== selectedCommand;

      return {
        ...state,
        selectedCommand,
        isCommandChanging,
        selectedPileItem: undefined,
        currentHistoryResponse: undefined,
        historySelectedCommand: undefined,
        historyCommandPayload: undefined,
        isSendCommandDisabled: false,
        isCommandPreview: false,
        commandPayload: {},
      };
    }
    case PAST_COMMAND_FROM_CLIPBOARD: {
      const { selectedCommand, commandPayload } = action.payload;

      const isCommandChanging =
        Boolean(state.historySelectedCommand) ||
        state.selectedCommand !== selectedCommand;

      return {
        ...state,
        selectedCommand,
        commandPayload,
        isCommandChanging,
        selectedPileItem: undefined,
        historySelectedCommand: undefined,
        historyCommandPayload: undefined,
        currentHistoryResponse: undefined,
        isCommandPreview: false,
        isSendCommandDisabled: false,
      };
    }
    case COMMAND_CHANGE_DONE: {
      return {
        ...state,
        isCommandChanging: false,
      };
    }
    case SET_SEND_COMMAND_DISABLED: {
      const isSendCommandDisabled =
        Boolean(state.selectedPileItem) || action.payload.disabled;

      return {
        ...state,
        isSendCommandDisabled,
      };
    }
    case SET_EXPLORING_STORAGE_NAME: {
      return {
        ...state,
        exploringStorageName: action.payload.storageName,
      };
    }
    case SAVE_CLOUD_STORAGE_PATH: {
      return {
        ...state,
        cloudStorageSavedPath: action.payload.path,
      };
    }
    case SAVE_SATELLITE_STORAGE_PATH: {
      return {
        ...state,
        satelliteStorageSavedPath: action.payload.path,
      };
    }
    case REORDER_COMMAND_PILE: {
      const fromIndex = action.payload.fromIndex;
      const toIndex = action.payload.toIndex;

      let commandPileWorkingSpace = state.commandPileWorkingSpace;

      if (!commandPileWorkingSpace) commandPileWorkingSpace = [];

      let selectedPileItems = [];
      if (!Array.isArray(action.payload.selectedElements)) {
        selectedPileItems.push(action.payload.selectedElements);
      } else {
        selectedPileItems = action.payload.selectedElements;
      }

      const newList = arrayMove(
        commandPileWorkingSpace,
        toIndex,
        fromIndex,
        selectedPileItems
      );

      return {
        ...state,
        commandPileWorkingSpace: newList as ICommandPileItem[],
      };
    }

    case TOGGLE_LEFT_PANEL: {
      return {
        ...state,
        isLeftSidePanelHidden: action.payload.isLeftPanelHidden,
      };
    }

    case TOGGLE_RIGHT_PANEL: {
      return {
        ...state,
        isRightSidePanelHidden: action.payload.isRightPanelHidden,
        rightPanelSelectedTab: action.payload.initialRightPanel,
      };
    }

    case TOGGLE_COMMAND_HISTORY_COLLAPSE: {
      return {
        ...state,
        isCommandHistoryCollapsed: action.payload.isCommandHistoryCollapsed,
      };
    }

    case SET_RIGHT_SIDE_SELECTED_TAB: {
      let tab = action.payload.selectedTab;
      if (!tab) {
        tab = 'parameters';
      }

      return {
        ...state,
        rightPanelSelectedTab: tab,
      };
    }

    default:
      return state;
  }
};
