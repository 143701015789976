import React, { useEffect, useState } from 'react';
import type { IconName } from '@blueprintjs/icons';
import { IconNames } from '@blueprintjs/icons';
import { Button, ButtonGroup, HTMLSelect, Intent } from '@blueprintjs/core';
import TagHeader from 'components/common/TagHeader';

import type { IFolder } from 'services/api/ops/cloudStorage';

import UTF8Mode from './modes/utf8';
import ImageMode from './modes/image';
import EpsLog from './modes/epsLog';
import TelemetryLog from './modes/telemetryLog';
import HexMode from './modes/hex';
import MarkdownView from './modes/markdown-view';

import s from './index.module.scss';
import HTMLView from './modes/html';

interface IProps {
  isFetching?: boolean | undefined;
  fileContent: IFolder | undefined;
  handleUploadClick?: (files: IFolder[]) => void;
  handleOnClose?: () => void;
  renderEmptyData?: React.ReactNode;
}

export interface IModeProps<T> {
  fileDetails: IFolder | undefined;
  renderedContent: T;
  setRenderedContent: (value: T) => void;
  setDidUserEdit?: (value: boolean) => void;
}

export interface IMode<T> {
  (props: IModeProps<T>): JSX.Element;
  isValid(fileDetails: IFolder): boolean;
  isPreferred(fileDetails: IFolder): boolean;
  id: string;
  isEditable: boolean;
  icon: IconName;
  saveContent(content: T): Uint8Array | undefined;
}

// Modes are sorted in order of preference.
// If more than one is "preferred", the first will be selected
// If none is preferred, the first valid will be selected
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const modes: IMode<any>[] = [
  UTF8Mode,
  ImageMode,
  HexMode,
  EpsLog,
  TelemetryLog,
  MarkdownView,
  HTMLView,
];

const getDefaultModeId = (fileDetails: IFolder | undefined) => {
  if (typeof fileDetails === 'undefined' || fileDetails === null) {
    return HexMode.id;
  }

  const validModes = modes.filter((m) => m.isValid(fileDetails));
  let preferredMode = validModes.find((m) => m.isPreferred(fileDetails));

  if (!preferredMode) {
    preferredMode = validModes[0] || HexMode;
  }

  return preferredMode.id;
};

const filterAvailableModeIds = (fileDetails: IFolder | undefined) => {
  if (typeof fileDetails === 'undefined') {
    return [HexMode.id];
  }
  return modes.filter((m) => m.isValid(fileDetails)).map((m) => m.id);
};

const FileEditor = (props: IProps) => {
  const [renderedContent, setRenderedContent] = useState<IMode<unknown>>();
  const [currentModeId, setCurrentModeId] = useState<string>(
    getDefaultModeId(props.fileContent)
  );
  const [availableModeIds, setAvailableModeIds] = useState<string[]>(
    filterAvailableModeIds(props.fileContent)
  );

  const [didUserEdit, setDidUserEdit] = useState<boolean>(false);

  const CurrentMode = modes.find((m) => m.id === currentModeId);

  const isEmptyFile =
    typeof props.fileContent === 'undefined' || props.fileContent === null;

  useEffect(() => {
    setCurrentModeId(getDefaultModeId(props.fileContent));
    setAvailableModeIds(filterAvailableModeIds(props.fileContent));
  }, [props.fileContent]);

  const handleModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentModeId(e.target.value);
  };

  const handleOnClose = () => {
    props.handleOnClose?.();
  };

  const handleOnSaveClick = () => {
    const file = CurrentMode?.saveContent(renderedContent);

    if (props.fileContent && file) {
      props.handleUploadClick?.([{ ...props.fileContent, file }]);
      handleOnClose();
      setDidUserEdit(false);
    }
  };

  return (
    <>
      {Boolean(!isEmptyFile) && (
        <TagHeader
          className={s.filePreviewHeaderIcon}
          icon={CurrentMode?.icon}
          title={
            <div className={s.filePreviewHeaderContainer}>
              <div className={s.filePreviewHeaderSelector}>
                <span>{props.fileContent?.name}</span>
                <HTMLSelect
                  minimal
                  value={currentModeId}
                  onChange={handleModeChange}
                >
                  {availableModeIds.map((modeId) => (
                    <option key={modeId} value={modeId}>
                      {modeId}
                    </option>
                  ))}
                </HTMLSelect>
              </div>
              {
                <ButtonGroup>
                  {CurrentMode?.isEditable && (
                    <Button
                      small
                      text="Save"
                      intent={Intent.PRIMARY}
                      icon={IconNames.FLOPPY_DISK}
                      onClick={handleOnSaveClick}
                      disabled={!didUserEdit}
                    />
                  )}
                  <Button
                    small
                    text="Close"
                    intent={Intent.DANGER}
                    icon={IconNames.CROSS}
                    onClick={handleOnClose}
                  />
                </ButtonGroup>
              }
            </div>
          }
        />
      )}
      {isEmptyFile ? (
        props.renderEmptyData
      ) : (
        <div className={s.filePreviewImageContainer}>
          {CurrentMode && (
            <CurrentMode
              fileDetails={props.fileContent}
              renderedContent={renderedContent}
              setRenderedContent={setRenderedContent}
              setDidUserEdit={setDidUserEdit}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FileEditor;
