import { Column } from 'ui/ColumnLayout/Column';
import { Header } from 'ui/Header';
import { ItemTabContainer } from 'ui/ItemContainer';
import { ActivitiesMap } from 'pages/ops/Scheduling/components/ActivitiesMap';
import { Text } from 'ui/Text';
import {
  CompositeDetailsItem,
  DetailsItem,
  TabularDetailsItem,
} from 'pages/ops/shared/components/DetailsItem';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import { Row } from 'ui/RowLayout/Row';
import { toFormattedDate, toFormattedTime } from 'utils/common/dateUtils';
import type { useTaskingOverviewData } from './useTaskingOverviewData';
import { NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import findSatelliteNameByMissionId from 'utils/findSatelliteNameByMissionId';
import Textarea from '_atoms/Textarea/Textarea';
import { Accordion, Button, Icon, Tooltip } from 'opencosmos-ui';
import type { GenericDetailsItem } from '_api/tasking/types';
import { CancelRequest } from '_organisms/CancelRequestPopup/CancelRequest';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import ActivityInfo from 'pages/shared/components/Tasking/ActivityInfo';
import { Link } from 'react-router-dom';

const TaskingOverviewScreen = ({
  taskingOverviewData,
}: {
  taskingOverviewData: ReturnType<typeof useTaskingOverviewData>;
}) => {
  const {
    selectedRequest,
    customer,
    isAddActivityModalOpen,
    setIsAddActivityModalOpen,
    refetchTaskingRequests,
    disableAddActivity,
    disableCancelRequest,
    isCancelRequestModalOpen,
    setIsCancelRequestModalOpen,
    selectedRequestSwathParameters,
  } = taskingOverviewData;

  const { translate } = useLocalisation();

  return (
    <>
      {!selectedRequest ? (
        <NonIdealState
          icon={IconNames.HELP}
          title="No request selected"
          description="Select a request to view it here"
          className="dark:text-item-dark-contrast text-item-contrast"
        />
      ) : (
        <>
          <div className="flex items-baseline gap-2">
            <Header size="h2">Tasking Request</Header>
            <span className="text-xs flex-1">
              with id <em aria-label="Request id">{selectedRequest.id}</em>
            </span>
            <ItemTabContainer>
              {selectedRequest.status !== 'CANCELLED' &&
                selectedRequest.status !== 'FULFILLED' &&
                selectedRequest.status !== 'FAILED' && (
                  <Tooltip
                    content="Tasking requests with past activities cannot be cancelled"
                    isDisabled={!selectedRequest}
                  >
                    <Button
                      className="w-24"
                      isDisabled={disableCancelRequest}
                      onPress={() => setIsCancelRequestModalOpen(true)}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                )}
            </ItemTabContainer>
          </div>

          {!isAddActivityModalOpen && (
            <ActivitiesMap
              activity={selectedRequest.activities}
              request={selectedRequest}
              aspectRatio="auto"
            />
          )}

          <RowLayout rows={2} rowRatio={[0.1, 1]} gap={5}>
            <Row>
              <Column>
                <div className="text-xs px-1 pb-1">
                  {selectedRequest.type} tasking request created by{' '}
                  <Link to={'/portal/users/' + encodeURIComponent(customer.id)}>
                    {customer.name} ({customer.email})
                  </Link>{' '}
                  on behalf of{' '}
                  <Link
                    to={
                      '/portal/organisation/' + selectedRequest.organisation_id
                    }
                  >
                    {selectedRequest.organisation_name}
                  </Link>{' '}
                  on {toFormattedDate(selectedRequest.created_at)}
                </div>

                <DetailsItem
                  aria-label="Request region name"
                  title="Region name:"
                  value={selectedRequest.region_name}
                />
                <DetailsItem
                  aria-label="Request project name"
                  title="Project:"
                  value={
                    <Link
                      to={
                        '/data/project/' +
                        selectedRequest.project_id +
                        '/tasking?panel=Existing'
                      }
                    >
                      {selectedRequest.project_name}
                    </Link>
                  }
                />

                <DetailsItem
                  aria-label="Area of Interest Coverage:"
                  title={
                    translate('datacosmos.tasking.legend.roiCoverage') + ':'
                  }
                  value={
                    <Tooltip
                      content={
                        <table>
                          <tr>
                            <td>Only fulfilled</td>
                            <td className="text-right">{`${
                              selectedRequestSwathParameters?.coverageValueCompleted ??
                              '-'
                            } km² (${
                              selectedRequestSwathParameters?.coveragePercentCompleted ??
                              ''
                            }%)`}</td>
                          </tr>
                          <tr>
                            <td>Only planned</td>
                            <td className="text-right">{`${
                              selectedRequestSwathParameters?.coverageValuePlanned ??
                              '-'
                            } km² (${
                              selectedRequestSwathParameters?.coveragePercentPlanned ??
                              ''
                            }%)`}</td>
                          </tr>
                        </table>
                      }
                      isDisabled={!selectedRequest}
                    >
                      <div className="flex-1 text-right">
                        {selectedRequestSwathParameters?.coverageValue ?? '-'}{' '}
                        km² (
                        {selectedRequestSwathParameters?.coveragePercent ?? ''}
                        %)
                      </div>
                    </Tooltip>
                  }
                />

                {selectedRequest.constraints?.length && (
                  <TabularDetailsItem
                    title="Constraints:"
                    value={
                      selectedRequest.constraints as unknown as GenericDetailsItem[]
                    }
                  />
                )}

                {selectedRequest.parameters?.objectives?.length && (
                  <TabularDetailsItem
                    title="Objectives:"
                    value={
                      selectedRequest.parameters
                        ?.objectives as unknown as GenericDetailsItem[]
                    }
                  />
                )}
                <CompositeDetailsItem
                  title="Customer Notes: "
                  element={<Textarea disabled value={selectedRequest.notes} />}
                />
              </Column>
            </Row>

            <Row>
              <Column data-testid="activities-column">
                <div className="flex items-baseline gap-2">
                  <Header size="h2">Activities</Header>
                  <span
                    aria-label="Number of activities"
                    className="text-xs flex-1"
                  >
                    ({selectedRequest.activities.length})
                  </span>
                  <Tooltip
                    content="Activities cannot be added to expired tasking requests"
                    isDisabled={!disableAddActivity}
                  >
                    <Button
                      className="w-24"
                      isDisabled={disableAddActivity}
                      onPress={() => setIsAddActivityModalOpen(true)}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </div>

                {selectedRequest.activities.map((a) => (
                  <Accordion
                    className="px-2 py-0"
                    key={a.id}
                    labelRenderer={() => (
                      <ItemTabContainer className="justify-between items-center dark:bg-item-dark bg-item">
                        <Icon icon="Satellite" size={24} />
                        <Text>
                          {findSatelliteNameByMissionId(a.mission_id)}
                        </Text>
                        <Text>{toFormattedDate(a.start_date)}</Text>
                        <Text>{toFormattedTime(a.start_date)}</Text>
                        <Text>-</Text>
                        <Text>{toFormattedTime(a.end_date)}</Text>
                        <Text>{a.status}</Text>
                        <Text>{a?.priority?.toString()}</Text>
                      </ItemTabContainer>
                    )}
                    labelText=""
                  >
                    <div className="py-2 pb-4 border-b-2 border-neutral mb-2 flex flex-col gap-3">
                      <ActivityInfo activity={a} />
                      <CompositeDetailsItem
                        title="Operator Notes: "
                        element={
                          <Textarea value={a?.operator_notes} disabled />
                        }
                      />
                    </div>
                  </Accordion>
                ))}
              </Column>
            </Row>
          </RowLayout>

          <CancelRequest
            isOpen={isCancelRequestModalOpen}
            setIsOpen={setIsCancelRequestModalOpen}
            refetchData={refetchTaskingRequests}
            request={selectedRequest}
          />
        </>
      )}
    </>
  );
};

export default TaskingOverviewScreen;
