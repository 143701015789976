import type { DateRange } from '@blueprintjs/datetime';
import { routeCatalog } from 'datacosmos/components/routePath';
import { isEmpty, isNil } from 'lodash';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useLocation } from 'react-router';

export type IFiltersProviderContext = ReturnType<typeof useFiltersProvider>;

export const FiltersProviderContext = createContext<IFiltersProviderContext>(
  null as unknown as IFiltersProviderContext
);

export const useFilters = () =>
  useContext<IFiltersProviderContext>(FiltersProviderContext);

interface IProps {
  children: React.ReactNode;
}

const setToSessionStorage = (key: string | null, value: unknown) => {
  if (key === null) return;
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value));
    return value;
  } catch (error) {
    return value;
  }
};

const getFromSessionStorage = (key: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(window.sessionStorage.getItem(key)!);
  } catch (error) {
    return null;
  }
};

const useFiltersProvider = () => {
  const location = useLocation();

  const filtersFor = useMemo(() => {
    if (location.pathname.includes('catalog')) {
      return 'catalog';
    } else if (location.pathname.includes('items')) {
      return 'project';
    } else if (location.pathname.includes('tasking')) {
      return 'tasking';
    } else {
      return null;
    }
  }, [location.pathname]);

  const DEFAULT_FILTER_STATES = useMemo(
    () => ({
      filters: {
        catalog: filtersFor === 'catalog' ? routeCatalog : null,
        satellites: [] as string[],
        productType: [] as string[],
        dateFrom: null as Date | null | undefined,
        dateTo: null as Date | null | undefined,
        minHours: null as Date | null | undefined,
        maxHours: null as Date | null | undefined,
        minSZA: undefined as number | undefined,
        maxSZA: undefined as number | undefined,
        minOZA: undefined as number | undefined,
        maxOZA: undefined as number | undefined,
        minGSD: 0 as number | undefined,
        maxGSD: 0 as number | undefined,
        minSunGlint: undefined as number | undefined,
        maxSunGlint: undefined as number | undefined,
        maxCloudCoverage: undefined as number | undefined,
        sessionIdFilter: undefined as string | undefined,
        processingLevel: [] as string[],
        sourceFilter: [] as string[],
        seasonsFilter: [] as string[],
        collectionTypeFilter: [] as string[],
        qaStatusFilter: [] as string[],
        assetType: [] as string[],
        imageBandFilter: [] as string[],
        resolutionFilter: [] as string[],
        sensorFilter: [] as string[],
        platformTypeFilter: [] as string[],
        taskingDateFrom: null as Date | null | undefined,
        taskingDateTo: null as Date | null | undefined,
        taskingStatusFilter: undefined as string | undefined,
      },
      AOI: undefined as GeoJSON.Polygon[] | undefined,
      filtersFor,
    }),
    [filtersFor]
  );

  const [satelliteFilters, setSatelliteFilters] = useState<string[]>([]);
  const [imageBandFilter, setImageBandFilter] = useState<string[]>([]);
  const [processingLevelFilter, setProcessingLevelFilter] = useState<string[]>(
    []
  );
  const [productTypeFilter, setProductTypeFilter] = useState<string[]>([]);
  const [assetTypeFilter, setAssetTypeFilter] = useState<string[]>([]);
  const [sourceFilter, setSourceFilter] = useState<string[]>([]);
  const [seasonsFilter, setSeasonsFilter] = useState<string[]>([]);
  const [collectionTypeFilter, setCollectionTypeFilter] = useState<string[]>(
    []
  );
  const [qaStatusFilter, setQaStatusFilter] = useState<string[]>([]);
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>([
    null,
    null,
  ]);
  type HoursRange = {
    gte: Date | null | undefined;
    lte: Date | null | undefined;
  };
  const [hoursRangeFilter, setHoursRangeFilter] = useState<HoursRange>({
    gte: undefined,
    lte: undefined,
  });
  type MinMax = { min?: number; max?: number };
  const [OZAFilter, setOZAFilter] = useState<MinMax>({
    min: undefined,
    max: undefined,
  });
  const [GSDFilter, setGSDFilter] = useState<MinMax>({
    min: undefined,
    max: undefined,
  });
  const [SZAFilter, setSZAFilter] = useState<MinMax>({
    min: undefined,
    max: undefined,
  });
  const [sunGlintFilter, setSunGlintFilter] = useState<MinMax>({
    min: undefined,
    max: undefined,
  });

  const [cloudCoverageFilter, setCloudCoverageFilter] = useState<
    number | undefined
  >(undefined);
  const [areaOfInterest, setAreaOfInterest] = useState<
    GeoJSON.Polygon[] | undefined
  >([]);

  const [resolutionFilter, setResolutionFilter] = useState<string[]>([]);
  const [sensorFilter, setSensorFilter] = useState<string[]>([]);
  const [platformTypeFilter, setPlatformTypeFilter] = useState<string[]>([]);
  const [taskingStatusFilter, setTaskingStatusFilter] = useState<
    string | undefined
  >(undefined);
  const [taskingDateRangeFilter, setTaskingDateRangeFilter] =
    useState<DateRange>([null, null]);
  const [sessionIdFilter, setSessionIdFilter] = useState<string | undefined>(
    undefined
  );

  const [filters, setFilters] = useState(DEFAULT_FILTER_STATES);

  const areFiltersApplied =
    !isEmpty(satelliteFilters) ||
    !isEmpty(productTypeFilter) ||
    !isNil(dateRangeFilter[0]) ||
    !isNil(dateRangeFilter[1]) ||
    !isNil(hoursRangeFilter.gte) ||
    !isNil(hoursRangeFilter.lte) ||
    SZAFilter.min !== undefined ||
    SZAFilter.max !== undefined ||
    OZAFilter.min !== undefined ||
    OZAFilter.max !== undefined ||
    GSDFilter.min !== 0 ||
    GSDFilter.max !== 0 ||
    !isNil(sunGlintFilter.min) ||
    !isNil(sunGlintFilter.min) ||
    !isNil(cloudCoverageFilter) ||
    !isEmpty(processingLevelFilter) ||
    !isEmpty(assetTypeFilter) ||
    !isEmpty(imageBandFilter) ||
    !isEmpty(resolutionFilter) ||
    !isEmpty(sensorFilter) ||
    !isEmpty(seasonsFilter) ||
    !isEmpty(collectionTypeFilter) ||
    !isEmpty(qaStatusFilter) ||
    !isEmpty(areaOfInterest) ||
    !isEmpty(platformTypeFilter) ||
    !isNil(sessionIdFilter) ||
    !isNil(taskingDateRangeFilter[0]) ||
    !isNil(taskingDateRangeFilter[1]) ||
    !isNil(taskingStatusFilter);

  const clearAllFilters = useCallback(() => {
    setDateRangeFilter([null, null]);
    setHoursRangeFilter({ gte: undefined, lte: undefined });
    setSatelliteFilters([]);
    setProductTypeFilter([]);
    setSZAFilter({
      min: undefined,
      max: undefined,
    });
    setOZAFilter({
      min: undefined,
      max: undefined,
    });
    setGSDFilter({
      min: undefined,
      max: undefined,
    });
    setSunGlintFilter({
      min: undefined,
      max: undefined,
    });
    setCloudCoverageFilter(undefined);
    setSessionIdFilter(undefined);
    setProcessingLevelFilter([]);
    setAssetTypeFilter([]);
    setSourceFilter([]);
    setSeasonsFilter([]);
    setCollectionTypeFilter([]);
    setQaStatusFilter([]);
    setImageBandFilter([]);
    setAreaOfInterest([]);
    setResolutionFilter([]);
    setSensorFilter([]);
    setPlatformTypeFilter([]);
    setTaskingDateRangeFilter([null, null]);
    setTaskingStatusFilter(undefined);
  }, []);

  const getNumberOfActiveFilters = useCallback(() => {
    let ctr: number = 0;
    let ctrProduct: number = 0;
    let ctrLocation: number = 0;
    let ctrPeriod: number = 0;
    let ctrImageProcessingLevel: number = 0;
    let ctrSensorSpecification: number = 0;
    let ctrObservationConditions: number = 0;
    let ctrDataSpecification: number = 0;
    let ctrInternal: number = 0;
    if (!isEmpty(satelliteFilters)) {
      ctr++;
      ctrProduct++;
    }
    if (!isEmpty(productTypeFilter)) {
      ctr++;
      ctrProduct++;
    }
    if (!isNil(dateRangeFilter[0])) {
      ctr++;
      ctrPeriod++;
    }
    if (!isNil(dateRangeFilter[1])) {
      ctr++;
      ctrPeriod++;
    }
    if (!isNil(hoursRangeFilter.gte)) {
      ctr++;
      ctrPeriod++;
    }
    if (!isNil(hoursRangeFilter.lte)) {
      ctr++;
      ctrPeriod++;
    }
    if (SZAFilter.min) {
      ctr++;
      ctrObservationConditions++;
    }
    if (SZAFilter.max) {
      ctr++;
      ctrObservationConditions++;
    }
    if (OZAFilter.min) {
      ctr++;
      ctrObservationConditions++;
    }
    if (OZAFilter.max) {
      ctr++;
      ctrObservationConditions++;
    }
    if (GSDFilter.min) {
      ctr++;
      ctrSensorSpecification++;
    }
    if (GSDFilter.max) {
      ctr++;
      ctrSensorSpecification++;
    }
    if (sunGlintFilter.min) {
      ctr++;
      ctrObservationConditions++;
    }
    if (sunGlintFilter.max) {
      ctr++;
      ctrObservationConditions++;
    }
    if (cloudCoverageFilter) {
      ctr++;
      ctrObservationConditions++;
    }
    if (!isEmpty(processingLevelFilter)) {
      ctr++;
      ctrImageProcessingLevel++;
    }
    if (!isEmpty(assetTypeFilter)) {
      ctr++;
      ctrDataSpecification++;
    }
    if (!isEmpty(imageBandFilter)) {
      ctr++;
      ctrSensorSpecification++;
    }
    if (!isEmpty(sourceFilter)) {
      ctr++;
      ctrDataSpecification++;
    }
    if (!isEmpty(seasonsFilter)) {
      ctr++;
      ctrPeriod++;
    }
    if (!isEmpty(collectionTypeFilter)) {
      ctr++;
      ctrInternal++;
    }
    if (!isEmpty(qaStatusFilter)) {
      ctr++;
      ctrInternal++;
    }
    if (sessionIdFilter) {
      ctr++;
      ctrInternal++;
    }
    if (!isEmpty(areaOfInterest)) {
      ctr++;
      ctrLocation++;
    }
    if (!isEmpty(resolutionFilter)) {
      ctr++;
      ctrDataSpecification++;
    }
    if (!isEmpty(sensorFilter)) {
      ctr++;
      ctrSensorSpecification++;
    }
    if (!isEmpty(platformTypeFilter)) {
      ctr++;
      ctrProduct++;
    }
    if (!isNil(taskingDateRangeFilter[0])) ctr++;
    if (!isNil(taskingDateRangeFilter[1])) ctr++;
    if (!isNil(taskingStatusFilter)) ctr++;
    return {
      total: ctr,
      product: ctrProduct,
      location: ctrLocation,
      period: ctrPeriod,
      advanced:
        ctrImageProcessingLevel +
        ctrSensorSpecification +
        ctrObservationConditions +
        ctrDataSpecification,
      imageProvessingLevel: ctrImageProcessingLevel,
      sensorSpecification: ctrSensorSpecification,
      observationConditions: ctrObservationConditions,
      dataSpecification: ctrDataSpecification,
      internal: ctrInternal,
    };
  }, [
    GSDFilter.max,
    GSDFilter.min,
    OZAFilter.max,
    OZAFilter.min,
    SZAFilter.max,
    SZAFilter.min,
    areaOfInterest,
    assetTypeFilter,
    cloudCoverageFilter,
    sessionIdFilter,
    dateRangeFilter,
    imageBandFilter,
    platformTypeFilter,
    processingLevelFilter,
    resolutionFilter,
    satelliteFilters,
    productTypeFilter,
    sourceFilter,
    seasonsFilter,
    collectionTypeFilter,
    qaStatusFilter,
    sunGlintFilter.max,
    sunGlintFilter.min,
    hoursRangeFilter.gte,
    hoursRangeFilter.lte,
    sensorFilter,
    taskingDateRangeFilter,
    taskingStatusFilter,
  ]);

  useEffect(() => {
    if (!filtersFor) return;

    const storageFilters = getFromSessionStorage(filtersFor) as
      | typeof DEFAULT_FILTER_STATES
      | null;

    if (!storageFilters) return;

    clearAllFilters();

    setFilters(storageFilters);

    setDateRangeFilter([
      storageFilters.filters.dateFrom
        ? new Date(storageFilters.filters.dateFrom)
        : null,
      storageFilters.filters.dateTo
        ? new Date(storageFilters.filters.dateTo)
        : null,
    ]);
    setHoursRangeFilter({
      gte: storageFilters.filters.minHours,
      lte: storageFilters.filters.maxHours,
    });
    setSatelliteFilters(storageFilters.filters.satellites);
    setProductTypeFilter(storageFilters.filters.productType ?? []);
    setSZAFilter({
      min: storageFilters.filters.minSZA,
      max: storageFilters.filters.maxSZA,
    });
    setOZAFilter({
      min: storageFilters.filters.minOZA,
      max: storageFilters.filters.maxOZA,
    });
    setGSDFilter({
      min: storageFilters.filters.minGSD,
      max: storageFilters.filters.maxGSD,
    });
    setSunGlintFilter({
      min: storageFilters.filters.minSunGlint,
      max: storageFilters.filters.maxSunGlint,
    });
    setCloudCoverageFilter(storageFilters.filters.maxCloudCoverage);
    setSessionIdFilter(storageFilters.filters.sessionIdFilter);
    setProcessingLevelFilter(storageFilters.filters.processingLevel);
    setAssetTypeFilter(storageFilters.filters.assetType);
    setSourceFilter(storageFilters.filters.sourceFilter);
    setSeasonsFilter(storageFilters.filters.seasonsFilter);
    setCollectionTypeFilter(storageFilters.filters.collectionTypeFilter);
    setQaStatusFilter(storageFilters.filters.qaStatusFilter);
    setImageBandFilter(storageFilters.filters.imageBandFilter);
    setAreaOfInterest(storageFilters.AOI);
    setResolutionFilter(storageFilters.filters.resolutionFilter);
    setTaskingStatusFilter(storageFilters.filters.taskingStatusFilter);
    setTaskingDateRangeFilter([
      storageFilters.filters.taskingDateFrom
        ? new Date(storageFilters.filters.taskingDateFrom)
        : null,
      storageFilters.filters.taskingDateTo
        ? new Date(storageFilters.filters.taskingDateTo)
        : null,
    ]);
  }, [clearAllFilters, filtersFor]);

  useEffect(() => {
    const newFilters: typeof DEFAULT_FILTER_STATES = {
      filters: {
        catalog: filtersFor === 'catalog' ? routeCatalog : null,
        satellites: satelliteFilters,
        productType: productTypeFilter,
        dateFrom: dateRangeFilter[0],
        dateTo: dateRangeFilter[1],
        minHours: hoursRangeFilter.gte,
        maxHours: hoursRangeFilter.lte,
        minSZA: SZAFilter.min,
        maxSZA: SZAFilter.max,
        minOZA: OZAFilter.min,
        maxOZA: OZAFilter.max,
        minGSD: GSDFilter.min,
        maxGSD: GSDFilter.max,
        minSunGlint: sunGlintFilter.min,
        maxSunGlint: sunGlintFilter.max,
        maxCloudCoverage: cloudCoverageFilter,
        sessionIdFilter: sessionIdFilter,
        processingLevel: processingLevelFilter,
        sourceFilter: sourceFilter,
        seasonsFilter: seasonsFilter,
        collectionTypeFilter: collectionTypeFilter,
        qaStatusFilter: qaStatusFilter,
        assetType: assetTypeFilter,
        imageBandFilter: imageBandFilter,
        resolutionFilter: resolutionFilter,
        sensorFilter: sensorFilter,
        platformTypeFilter: platformTypeFilter,
        taskingDateFrom: taskingDateRangeFilter[0],
        taskingDateTo: taskingDateRangeFilter[1],
        taskingStatusFilter: taskingStatusFilter,
      },
      AOI: areaOfInterest,
      filtersFor,
    };

    if (areFiltersApplied) {
      setToSessionStorage(filtersFor, newFilters);
      setFilters(newFilters);
    } else {
      setToSessionStorage(filtersFor, DEFAULT_FILTER_STATES);
      setFilters(DEFAULT_FILTER_STATES);
    }
  }, [
    DEFAULT_FILTER_STATES,
    GSDFilter.max,
    GSDFilter.min,
    OZAFilter.max,
    OZAFilter.min,
    SZAFilter.max,
    SZAFilter.min,
    areFiltersApplied,
    areaOfInterest,
    assetTypeFilter,
    cloudCoverageFilter,
    sessionIdFilter,
    dateRangeFilter,
    filtersFor,
    imageBandFilter,
    platformTypeFilter,
    processingLevelFilter,
    resolutionFilter,
    satelliteFilters,
    productTypeFilter,
    sourceFilter,
    seasonsFilter,
    collectionTypeFilter,
    qaStatusFilter,
    sunGlintFilter.max,
    sunGlintFilter.min,
    hoursRangeFilter.gte,
    hoursRangeFilter.lte,
    sensorFilter,
    taskingDateRangeFilter,
    taskingStatusFilter,
  ]);

  return {
    GSDFilter,
    setAreaOfInterest,
    productTypeFilter,
    processingLevelFilter,
    imageBandFilter,
    cloudCoverageFilter,
    clearAllFilters,
    assetTypeFilter,
    areaOfInterest,
    areFiltersApplied,
    OZAFilter,
    SZAFilter,
    setAssetTypeFilter,
    setCloudCoverageFilter,
    setGSDFilter,
    setImageBandFilter,
    setOZAFilter,
    setProcessingLevelFilter,
    setProductTypeFilter,
    setSZAFilter,
    setSourceFilter,
    setSeasonsFilter,
    setSunGlintFilter,
    sourceFilter,
    seasonsFilter,
    collectionTypeFilter,
    setCollectionTypeFilter,
    qaStatusFilter,
    setQaStatusFilter,
    sunGlintFilter,
    filters,
    satelliteFilters,
    setSatelliteFilters,
    dateRangeFilter,
    setDateRangeFilter,
    hoursRangeFilter,
    setHoursRangeFilter,
    setFilters,
    getNumberOfActiveFilters,
    setResolutionFilter,
    resolutionFilter,
    sensorFilter,
    setSensorFilter,
    platformTypeFilter,
    setPlatformTypeFilter,
    taskingDateRangeFilter,
    setTaskingDateRangeFilter,
    taskingStatusFilter,
    setTaskingStatusFilter,
    sessionIdFilter,
    setSessionIdFilter,
  };
};

const FiltersProvider = ({ children }: IProps) => {
  return (
    <FiltersProviderContext.Provider value={useFiltersProvider()}>
      {children}
    </FiltersProviderContext.Provider>
  );
};

export default FiltersProvider;
