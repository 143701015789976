import { ColumnConfig } from "./Table";

type AnyObject = Record<
  string,
  string | number | boolean | Object | null | undefined
>;

/**
 * mergeObjects is a function that merges multiple objects into a single object.
 * It does a deep merge, meaning that it will merge nested objects as well.
 *
 * This is used to obtain every possible column from a list of objects.
 * @param objects
 * @returns
 */
export function mergeObjects(objects: AnyObject[]): AnyObject {
  return objects.reduce((acc, obj) => mergeDeep(acc, obj), {});
}

function mergeDeep(target: AnyObject, source: AnyObject): AnyObject {
  for (const key in source) {
    if (
      source[key] !== null &&
      typeof source[key] === "object" &&
      !Array.isArray(source[key])
    ) {
      // If the value is an object, recursively merge it
      target[key] = mergeDeep(
        (target[key] as AnyObject) || {},
        source[key] as AnyObject
      );
    } else if (source[key] !== null && target[key] == null) {
      // If the source value is not null and target value is null or undefined, use the source value
      target[key] = source[key];
    } else if (target[key] == null) {
      // If the target value is still null or undefined, use the source value
      target[key] = source[key];
    }
  }

  return target;
}

/**
 * capitalizeTitle is a helper function that capitalizes the first letter of the string.
 * @param str string to capitalize
 * @returns capitalized string
 */
export const capitalizeTitle = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * deepEqual is a function that compares two objects and returns true if they are equal.
 * @param obj first object to compare
 * @param obj2 second object to compare
 * @returns true if the objects are equal, false otherwise
 */
export function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

/**
 * Get the leaf columns of an object. This function will return an array of strings that represent the leaf columns of the object.
 * @param data - The object to get the leaf columns from
 * @param key - The key of the object. This is used for recursive calls to get the nested leaf columns
 */
export const getLeafColumnIds = <
  T extends Record<
    string,
    string | number | boolean | T | Object | undefined | null
  >
>(
  data: T,
  excludeKeys: string[] = [],
  key: string = ""
): string[] => {
  return Object.entries(data)
    .filter(([k]) => !excludeKeys.includes(key ? `${key}.${k}` : k))
    .flatMap(([k, value]) => {
      if (
        !Array.isArray(value) &&
        typeof value === "object" &&
        value !== null
      ) {
        return getLeafColumnIds(
          value as T,
          excludeKeys,
          key !== "" ? `${key}.${k}` : k.toString()
        );
      }

      const uniqueId = key !== "" ? `${key}.${k}` : (k.toString() as any);
      return uniqueId;
    });
};

/**
 * deArrayify is a function that iterates over an array of objects and flattens any arrays of objects into a comma-separated string.
 *
 * This is useful for converting an array of objects into a format that can be displayed in a table.
 *
 * For example, if you have an array of objects like this:
 *
 * ```ts
 * [
 *   {
 *     name: 'John',
 *     age: 30,
 *     hobbies: [
 *       { id: 1, name: 'reading' },
 *       { id: 2, name: 'writing' }
 *     ]
 *   },
 *   {
 *     name: 'Jane',
 *     age: 25,
 *     hobbies: [
 *       { id: 3, name: 'swimming' },
 *       { id: 4, name: 'running' }
 *     ]
 *   }
 * ]
 * ```
 *
 * The resulting array will be:
 *
 * ```ts
 * [
 *   {
 *     name: 'John',
 *     age: 30,
 *     hobbies: {
 *       id: '1, 2',
 *       name: 'reading, writing'
 *     }
 *   },
 *   {
 *     name: 'Jane',
 *     age: 25,
 *     hobbies: {
 *       id: '3, 4',
 *       name: 'swimming, running'
 *     }
 *   }
 * ]
 * ```
 *
 * @param data - The array of objects to de-arrayify
 * @returns The de-arrayified array of objects
 */
export function deArrayify<T extends Record<string, any>>(data: T[]): T[] {
  return data.map((item) => {
    const flattenedItem: Record<string, any> = {};

    Object.entries(item).forEach(([key, value]) => {
      // If value is an array, flatten it to a comma-separated string or further structure if array of objects
      if (Array.isArray(value)) {
        if (
          value.length > 0 &&
          typeof value[0] === "object" &&
          !Array.isArray(value[0])
        ) {
          flattenedItem[key] = value.reduce((acc, v) => {
            Object.entries(v).forEach(([k, v]) => {
              acc = {
                ...acc,
                [k]: acc[k] ? [...new Set([acc[k], v])].join(", ") : v,
              };
            });

            return acc;
          }, {});
        } else {
          // If array contains non-object items, just join them as a string
          flattenedItem[key] = value.join(", ");
        }
      } else if (typeof value === "object" && value !== null) {
        // Recursively flatten nested objects
        flattenedItem[key] = deArrayify([value])[0];
      } else {
        // Otherwise, keep the original value
        flattenedItem[key] = value;
      }
    });

    return flattenedItem as T;
  });
}
