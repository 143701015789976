import DangerButton from '_molecules/Button/DangerButton';
import PrimaryButton from '_molecules/Button/PrimaryButton';
import OrderCard from '_organisms/OrderCard/OrderCard';
import OrderItemCard from '_organisms/OrderItemCard/OrderItemCard';
import {
  CompositeDetailsItem,
  DetailsItem,
} from 'pages/ops/shared/components/DetailsItem';
import React, { useMemo } from 'react';
import { DateInput } from 'ui/DateInput';
import { Header } from 'ui/Header';
import { ItemTabContainer } from 'ui/ItemContainer';
import { Navbar } from 'ui/Navbar';
import { Screen } from 'ui/Screen';
import { Text } from 'ui/Text';
import { useOrdersScreenData } from './useOrdersScreenData';
import { NonIdealState, Spinner } from '@blueprintjs/core';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { KM2 } from 'datacosmos/entities/polygonLayer';
import { Button } from 'opencosmos-ui';

const OrdersScreen = () => {
  const {
    ordersByDate,
    isOrdersLoading,
    toggleOrderCheck,
    checkedOrder,
    setToDateFilter,
    setFromDateFilter,
    fromDateFilter,
    toDateFilter,
    toggleOrderSelect,
    isOrderSelected,
    selectedOrder,
    customer,
    isCustomerLoading,
    isAdmin,
    markOrderAsPaid,
    markOrderAsCancelled,
    getOrderTotalCost,
    isOrderChecked,
  } = useOrdersScreenData();

  const { translate } = useLocalisation();

  const withNoOrderSelected = (children: React.ReactNode) => {
    if (!selectedOrder) {
      return (
        <NonIdealState
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrderSelected')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrderSelectedDescription'
          )}
          className="dark:text-item-dark-contrast"
          icon="info-sign"
        />
      );
    }

    return children;
  };

  const withNoOrdersFoundInDateRange = (children: React.ReactNode) => {
    if (
      ordersByDate &&
      Object.keys(ordersByDate).length === 0 &&
      (fromDateFilter || toDateFilter)
    ) {
      return (
        <NonIdealState
          icon="info-sign"
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrdersFound')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrdersFoundFiltersDescription'
          )}
          className="dark:text-item-dark-contrast"
        />
      );
    }

    return children;
  };

  const withNoOrdersFetched = (children: React.ReactNode) => {
    if (
      ordersByDate &&
      Object.keys(ordersByDate).length === 0 &&
      (!fromDateFilter || !toDateFilter)
    ) {
      return (
        <NonIdealState
          icon="info-sign"
          title={
            <span className="dark:text-item-dark-contrast">
              {translate('datacosmos.fetchErrors.orders.order.noOrdersFound')}
            </span>
          }
          description={translate(
            'datacosmos.fetchErrors.orders.order.noOrdersFoundDescription'
          )}
          className="dark:text-item-dark-contrast"
        />
      );
    }

    return children;
  };

  const withLoadingOrders = (children: React.ReactNode) => {
    if (isOrdersLoading) {
      return <Spinner />;
    }

    return children;
  };

  const selectedOrderTotalCost = useMemo(() => {
    if (!selectedOrder) {
      return;
    }
    return getOrderTotalCost(selectedOrder);
  }, [selectedOrder, getOrderTotalCost]);

  return (
    <Screen>
      <Navbar hideThemesInUserMenu={false} />
      <div className="flex color-surface overflow-hidden gap-2 orders-container">
        <div className="min-w-[66%] orders-section">
          <div className="flex justify-between items-center gap-2 px-2 gap-2">
            <Header size="h1">{translate('datacosmos.orders.title')}</Header>
            <ItemTabContainer>
              <DateInput
                setValue={(from) => {
                  setFromDateFilter(from);
                }}
                value={fromDateFilter}
                title={translate('datacosmos.orders.start')}
              />
              <DateInput
                setValue={(to) => {
                  setToDateFilter(to);
                }}
                value={toDateFilter}
                title={translate('datacosmos.orders.end')}
              />
            </ItemTabContainer>
            <Button
              onPress={() => window.print()}
              icon="Print"
              className={'no-print'}
            >
              {translate('datacosmos.orders.buttons.printOrders')}
            </Button>
          </div>

          {checkedOrder.every((or) => or.status !== 'CANCELLED') ? (
            <div className="no-print">
              {checkedOrder.length > 0 && (
                <ItemTabContainer className="flex justify-end gap-1">
                  <Text>
                    {translate('datacosmos.orders.buttons.selectedActions')}
                  </Text>
                  {isAdmin &&
                    checkedOrder.every((or) => or.status === 'UNPAID') && (
                      <PrimaryButton
                        text={translate('datacosmos.orders.buttons.markAsPaid')}
                        onPress={async () => {
                          if (checkedOrder.length === 0) {
                            return;
                          }
                          await Promise.all(
                            checkedOrder.map((o) => markOrderAsPaid(o))
                          );
                        }}
                      />
                    )}
                  <DangerButton
                    text={translate('datacosmos.orders.buttons.cancel')}
                    onPress={async () => {
                      if (checkedOrder.length === 0) {
                        return;
                      }
                      await Promise.all(
                        checkedOrder.map((o) => markOrderAsCancelled(o))
                      );
                    }}
                  />
                </ItemTabContainer>
              )}
            </div>
          ) : (
            <></>
          )}

          <div
            className="p-2 overflow-auto overflow-x-hidden h-screen max-h-[85%] orders-list"
            data-testid="orders-list"
          >
            {withLoadingOrders(
              withNoOrdersFetched(
                withNoOrdersFoundInDateRange(
                  Object.entries(ordersByDate ?? {}).map(([date, orders]) => (
                    <div key={date} className="flex flex-col gap-2 mb-2">
                      <Header size="h3">{date}</Header>
                      {orders.map((o) => (
                        <OrderCard
                          onClick={() => {
                            toggleOrderSelect(o);
                          }}
                          key={o.id}
                          fieldsToShow={['all']}
                          order={o}
                          isSelected={isOrderSelected(o)}
                          checkbox={{
                            onChange: () => {
                              toggleOrderCheck(o);
                            },
                            isSelected: isOrderChecked(o),
                          }}
                          orderCost={getOrderTotalCost(o)}
                        />
                      ))}
                    </div>
                  ))
                )
              )
            )}
          </div>
        </div>

        {/* Details */}
        <div className="overflow-auto overflow-x-hidden no-print block mx-auto">
          {withNoOrderSelected(
            <div className=" flex flex-col gap-4 p-2">
              <div className="flex justify-between items-center">
                <Header size="h2">
                  {translate('datacosmos.orders.details')}
                </Header>
                {selectedOrder?.status !== 'CANCELLED' && (
                  <ItemTabContainer className="gap-1 items-center mt-2">
                    {isAdmin && selectedOrder?.status === 'UNPAID' && (
                      <PrimaryButton
                        text={translate('datacosmos.orders.buttons.markAsPaid')}
                        onPress={async () => {
                          if (!selectedOrder) {
                            return;
                          }

                          await markOrderAsPaid(selectedOrder);
                        }}
                      />
                    )}
                    <DangerButton
                      text={translate('datacosmos.orders.buttons.cancel')}
                      onPress={async () => {
                        if (!selectedOrder) {
                          return;
                        }

                        await markOrderAsCancelled(selectedOrder);
                      }}
                    />
                  </ItemTabContainer>
                )}
              </div>

              <div className="min-w-min flex flex-col gap-2">
                <DetailsItem
                  title={translate('datacosmos.orders.orderId')}
                  value={selectedOrder?.id ?? '-'}
                />
                <DetailsItem
                  title={translate('datacosmos.orders.orderStatus.title')}
                  value={
                    selectedOrder?.status
                      ? translate(
                          `datacosmos.orders.orderStatus.${selectedOrder.status}`
                        )
                      : '-'
                  }
                />
                <DetailsItem
                  title={translate('datacosmos.orders.payment.method')}
                  value={
                    selectedOrder?.payment_method
                      ? translate(
                          `datacosmos.orders.payment.${selectedOrder.payment_method}`
                        )
                      : '-'
                  }
                />
                <DetailsItem
                  title={translate('datacosmos.orders.payment.id')}
                  value={selectedOrder?.payment_reference_number ?? '-'}
                />

                <DetailsItem
                  title={translate('datacosmos.orders.payment.userReferenceId')}
                  value={selectedOrder?.external_payment_id ?? '-'}
                />

                <DetailsItem
                  title={translate('datacosmos.orders.accessStatus.downloaded')}
                  //TODO: Add when available
                  value={'-'}
                />
                <DetailsItem
                  title={translate('datacosmos.orders.accessStatus.visualised')}
                  //TODO: Add when available
                  value={'-'}
                />
                <DetailsItem
                  title={translate('datacosmos.orders.accessStatus.expiresIn')}
                  //TODO: Add when available
                  value={'-'}
                />
                <CompositeDetailsItem
                  title={translate('datacosmos.orders.orderedBy')}
                  element={
                    isCustomerLoading ? (
                      <Spinner size={12} />
                    ) : (
                      customer?.name ?? selectedOrder?.created_by?.split('|')[1]
                    )
                  }
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <Header size="h2">
                    {selectedOrder?.type === 'IMAGE'
                      ? translate('datacosmos.orders.items.title')
                      : translate('datacosmos.orders.items.taskingDetails')}
                  </Header>
                  <div className="text-item-contrast dark:text-item-dark-contrast font-semibold">
                    {translate('datacosmos.orders.items.totalCost')}:{' '}
                    {selectedOrderTotalCost}
                  </div>
                </div>
                {selectedOrder?.order_line_items.map((item) =>
                  selectedOrder?.type === 'IMAGE' ? (
                    <OrderItemCard orderItem={item} key={item.item} />
                  ) : (
                    <div
                      className="flex flex-col gap-1 bg-item dark:bg-item-dark p-1"
                      key={item.item}
                    >
                      <DetailsItem
                        aria-label="Request id"
                        title={`${translate(
                          'datacosmos.orders.items.requestId'
                        )}:`}
                        value={
                          item?.tasking_request_id?.length
                            ? item?.tasking_request_id
                            : 'N/A'
                        }
                      />

                      <DetailsItem
                        aria-label="Request type"
                        title={`${translate('datacosmos.tasking.new.type')}:`}
                        value={
                          item?.tasking_request_type?.length
                            ? item?.tasking_request_type
                            : 'N/A'
                        }
                      />

                      <DetailsItem
                        aria-label="Mission id"
                        title={`${translate(
                          'datacosmos.orders.items.missionId'
                        )}:`}
                        value={
                          item?.mission_id?.length ? item?.mission_id : 'N/A'
                        }
                      />

                      <DetailsItem
                        aria-label="Area"
                        title={`${translate('datacosmos.orders.items.area')}:`}
                        value={
                          item?.area
                            ? `${Number(item?.area / KM2).toFixed(2)} km²`
                            : 'N/A'
                        }
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Screen>
  );
};

export default OrdersScreen;
