import React, { useRef, useState } from 'react';
import s from './InputFileButton.module.scss';
import { Button } from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';

interface IProps {
  id: string;
  disabled?: boolean;
  className?: string;
  text?: string;
  icon?: IconName;
  multiple?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onMouseOut?: () => void;
  onMouseOver?: () => void;
  accept?: string;
}

const FILE_INPUT_1 = 'fileInput-1';
const FILE_INPUT_2 = 'fileInput-2';

const InputFileButton = (props: IProps) => {
  const [fileInputRefresher, setFileInputRefresher] = useState(FILE_INPUT_1);
  const inputRef = useRef<HTMLInputElement>(null);
  const renderIcon = props.icon;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(e);

    /* This is a super hack to make browser pick up the same file submitted twice */
    setFileInputRefresher(
      fileInputRefresher === FILE_INPUT_1 ? FILE_INPUT_2 : FILE_INPUT_1
    );
  };

  const renderFileInput = (key: string) => (
    <div key={key}>
      <Button
        icon={renderIcon}
        onPress={() => {
          inputRef?.current?.click();
        }}
        size="base"
        className={'p-1.5 w-full'}
        isMinimal
      >
        {props.text}
      </Button>

      <input
        disabled={props.disabled}
        className={[s.inputFileWrapper, 'hidden'].join(' ')}
        id={props.id}
        onBlur={props.onBlur}
        onMouseOut={!props.disabled ? props.onBlur : undefined}
        onFocus={props.onFocus}
        onMouseOver={!props.disabled ? props.onFocus : undefined}
        onChange={handleOnChange}
        type="file"
        ref={inputRef}
        accept={props.accept}
      />
    </div>
  );

  return renderFileInput(fileInputRefresher);
};

export default InputFileButton;
