/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
import { Intent } from '@blueprintjs/core';
import { ASTRUMS } from 'constants/API/constant';
import { toaster } from 'toaster';
import { MODE } from 'env';
import { clientTranslate } from 'utils/hooks/useLocalisation';

interface IDescObj {
  [key: string]: number;
}

export function desc(a: IDescObj, b: IDescObj, orderBy: string): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function prepareFile(
  content: BlobPart,
  _fileName: string,
  contentType?: string
): string {
  const file = new Blob([content], { type: contentType });
  return URL.createObjectURL(file);
}

export function downloadFileFromURL(src: string, fileName: string): string {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = src;
  a.download = fileName;
  if (new URL(src).hostname !== window.location.hostname) {
    a.target = '_blank';
  }
  a.click();
  document.body.removeChild(a);
  return src;
}

export function downloadFile(
  content: BlobPart,
  fileName: string,
  contentType?: string
): string {
  const src = prepareFile(content, fileName, contentType);
  downloadFileFromURL(src, fileName);
  return src;
}

//This function downloads the file to the corresponding fileName passed as 'name' argument
export const downloadToFileName = async (
  url: string | undefined,
  name: string,
  contentType?: string
) => {
  if (!url) {
    return;
  }
  const res = await fetch(url);
  const blob = await res.blob();
  downloadFile(blob, name, contentType);
};

export function getHost() {
  const port = window.location.port ? `:${window.location.port}` : '';
  return `${window.location.protocol}//${window.location.hostname}${port}`;
}

export const isDevMode = () => {
  return (
    MODE === 'development' ||
    window.location.hostname.startsWith('test.') ||
    window.location.hostname.startsWith('staging.') ||
    window.location.hostname.includes('preview.') ||
    window.location.hostname.startsWith('localhost')
  );
};

export function setAuthorisationCookie(
  cname: string,
  cookieValue: string | undefined,
  expireHours?: number
) {
  let expires = '';
  if (expireHours !== undefined) {
    const d = new Date();
    d.setTime(d.getTime() + expireHours * 60 * 60 * 1000);
    expires = 'expires=' + d.toUTCString();
  }
  const extraCookieOptions = isDevMode() ? 'samesite=None;secure;' : '';
  document.cookie = `${cname}=${
    cookieValue ?? ''
  };${extraCookieOptions}${expires};path=/`;
}

export const preventDefaultPropagation =
  (fn: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    e.stopPropagation();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return fn(...args);
  };

export const getAudienceByDomain = (domain: string) => {
  if (
    domain === 'https://app.open-cosmos.com' ||
    domain === 'https://beta.app.open-cosmos.com'
  ) {
    return 'https://beeapp.open-cosmos.com';
  }

  return 'https://test.beeapp.open-cosmos.com';
};

export const getAstrum = (astrumStr: string): ASTRUMS => {
  for (const astrum in ASTRUMS) {
    if (astrum === astrumStr) {
      return ASTRUMS[astrum as keyof typeof ASTRUMS];
    }
  }
  //@ts-expect-error
  return undefined;
};

export const swapArrayItems = (
  arr: unknown[],
  indexA: number,
  indexB: number
) => {
  const temp = arr[indexA];

  arr[indexA] = arr[indexB];
  arr[indexB] = temp;

  return arr;
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const toArray = (item: unknown | unknown[]) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  isArray(item) ? item : [item];

export const copyToClipBoard = (text: string) => {
  void navigator.clipboard.writeText(text).then(() => {
    toaster.show({
      message: clientTranslate('datacosmos.catalogAndItems.metadata.copied'),
      icon: IconNames.ISSUE,
      intent: Intent.SUCCESS,
      timeout: 600,
    });
  });
};

export const showErrorMessage = (message: string) =>
  toaster.show({
    message: message,
    icon: IconNames.ISSUE,
    intent: Intent.DANGER,
  });

export const showSuccessMessage = (message: string, timeout?: number) =>
  toaster.show({
    message,
    icon: IconNames.ISSUE,
    intent: Intent.SUCCESS,
    timeout,
  });

// From https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
export const arrayMove = <T>(
  arr: T[],
  old_index: number,
  new_index: number,
  selectedElements?: T[]
) => {
  if (new_index >= arr.length) {
    throw new Error('new_index out of bounds');
  }
  if (old_index >= arr.length) {
    throw new Error('old_index out of bounds');
  }
  const arrCopy = [...arr];

  // the following lines handles both single and multiple commands reordering
  //filters the array of all the selected commands
  const draggedCommands = arrCopy.filter((command) =>
    selectedElements?.includes(command)
  );

  //removes existing elements from parent array
  const updatedArrCopy = arrCopy.filter((item) => {
    return !selectedElements?.includes(item);
  });

  //adds new dragged elements to the new index
  updatedArrCopy.splice(new_index, 0, ...draggedCommands);
  return updatedArrCopy;
};

export function getFilenameFromURL(url: string): string | undefined {
  return url.split('#')[0].split('?')[0].split('/').pop();
}

export function parseCopyright(values: string[]) {
  const regex = /(\d{4})/; // Regular expression to match a 4-digit year
  const groups: { [key: string]: string[] } = {};
  const noYearStrings: string[] = [];

  values.forEach((str) => {
    const match = str.match(regex);
    if (match) {
      const year = match[0];
      const fixedPart = str.replace(regex, '').trim();
      if (!groups[fixedPart]) {
        groups[fixedPart] = [];
      }
      groups[fixedPart].push(year);
    } else {
      noYearStrings.push(str);
    }
  });

  const mergedStrings = Object.entries(groups).map(([fixedPart, years]) => {
    const uniqueYears = [...new Set(years)].join(', ');
    return `${fixedPart} ${uniqueYears}`;
  });

  return [...mergedStrings, ...noYearStrings];
}

export function formatPrice(price: number | string): string {
  // TODO: Use i18next for currency formatting when the locale is correctly handled
  const value = typeof price === 'string' ? Number(price) : price;
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}
