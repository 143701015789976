import type { PixelData } from '_api/sampling/service';
import { getLinePixelData } from '_api/sampling/service';
import { useCallback, useState } from 'react';

export type CommonChartData = {
  position: number;
  latitude?: number;
  longitude?: number;
};

export type MultiBandChartData = CommonChartData & {
  rValue: number | string;
  gValue: number | string;
  bValue: number | string;
};

export type SingleBandChartData = CommonChartData & {
  value: number | string;
};

export type LinePixelsChartData = MultiBandChartData[] | SingleBandChartData[];

export const isSingleBandChartData = (
  data: MultiBandChartData[] | SingleBandChartData[]
): data is SingleBandChartData[] =>
  (data[0] as SingleBandChartData).value !== undefined;

export const useCustomLinePixels = () => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [customLinePixelsChartData, setCustomLinePixelsChartData] = useState<
    LinePixelsChartData | undefined
  >();

  const pixelDataToChartCompatiblePixelData = (
    data: PixelData[] | undefined
  ): MultiBandChartData[] | SingleBandChartData[] | undefined => {
    if (!data) {
      return;
    }

    if (data[0].value.length > 1) {
      return data.map((d, i) => ({
        position: (i * 100) / data.length,
        rValue: d.value[0],
        gValue: d.value[1],
        bValue: d.value[2],
      }));
    }

    return data.map((d, i) => ({
      position: (i * 100) / data.length,
      value: d.value[0],
    }));
  };

  const getLineOfInterestPixelData = useCallback(
    async (lineOfInterest: GeoJSON.Feature, url: string | undefined) => {
      if (
        !lineOfInterest ||
        !url ||
        lineOfInterest.geometry.type !== 'LineString'
      ) {
        return;
      }

      setIsFetching(true);
      const response = await getLinePixelData({
        params: {
          url,
          start_latitude: lineOfInterest.geometry.coordinates[0][1],
          start_longitude: lineOfInterest.geometry.coordinates[0][0],
          end_latitude: lineOfInterest.geometry.coordinates[1][1],
          end_longitude: lineOfInterest.geometry.coordinates[1][0],
        },
      });
      setIsFetching(false);

      if (response.data) {
        setCustomLinePixelsChartData(
          pixelDataToChartCompatiblePixelData(response.data) ?? []
        );
      }
    },
    []
  );

  const toggleCustomLinePixelValuesMode = (toggle?: boolean) => {
    if (isToggled) {
      setCustomLinePixelsChartData(undefined);
    }
    setIsToggled(toggle !== undefined ? toggle : !isToggled);
  };

  return {
    getLineOfInterestPixelData,
    isToggled,
    toggleCustomLinePixelValuesMode,
    isFetching,
    customLinePixelsChartData,
  };
};
