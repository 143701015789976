import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import { useCurrentTerminalSession } from 'pages/ops/RTI/Operate/context/TerminalSessionProvider';
import {
  clearCurrentPayload,
  clearCommandCustomName,
  selectCommandFromSuggestion,
  showRightPanel,
} from 'actions/operate/actions';
import { CUSTOM_COMMAND_FIELD_NAME } from 'pages/ops/RTI/Operate/components/Parameters';
import { useCallback } from 'react';
import type { ICommandPileItem } from 'services/api/ops/realtimeTerminalSession';
import { v1 as uuid } from 'uuid';
import type { CommandHistoryPayload } from './useCommandHistory';
import type { CommandDefinition } from '_api/telecommands/types';

export const useCommandEditorHandlers = () => {
  const {
    dispatch,
    state: {
      selectedCommand,
      //TODO: Fix this eslint-disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      commandPayload,
      commandCustomName,
      commandPileWorkingSpace,
      armedCommand,
      frozenPile,
    },
  } = useOperate();

  const { updateWorkspace } = useCurrentTerminalSession();

  const handleSendToPileCommand = useCallback(() => {
    if (!selectedCommand?.name) return;

    const pileItem: ICommandPileItem = {
      command: selectedCommand.name,
      payload: commandPayload as CommandHistoryPayload,
      customName: commandCustomName,
      id: uuid(),
      arguments: undefined,
      procedureName: null,
    };

    dispatch(clearCurrentPayload());
    dispatch(clearCommandCustomName());
    updateWorkspace(
      [...(commandPileWorkingSpace ?? []), pileItem],
      armedCommand,
      Boolean(frozenPile)
    );
  }, [
    armedCommand,
    commandCustomName,
    commandPayload,
    commandPileWorkingSpace,
    dispatch,
    frozenPile,
    selectedCommand?.name,
    updateWorkspace,
  ]);

  const handleSelectCommandEditorItem = useCallback(
    (command: CommandDefinition) => {
      dispatch(selectCommandFromSuggestion(command));
      dispatch(showRightPanel('parameters'));

      const customNameInputs = document.getElementsByName(
        CUSTOM_COMMAND_FIELD_NAME
      );
      if (customNameInputs.length > 0) {
        customNameInputs[0].focus();
      }
    },
    [dispatch]
  );

  return {
    handleSendToPileCommand,
    handleSelectCommandEditorItem,
  };
};
