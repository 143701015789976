import { useCallback } from 'react';
import { useOperate } from 'pages/ops/RTI/Operate/context/OperateProvider';
import type {
  ICommandPileItem,
  ISendUnarmedCommand,
} from 'services/api/ops/realtimeTerminalSession';
import { getCurrentUtcDate } from 'utils/common/dateUtils';
import { useCurrentTerminalSession } from 'pages/ops/RTI/Operate/context/TerminalSessionProvider';
import { getPrunedCommandPayload } from 'pages/ops/RTI/Operate/utils/rtiCommands/getPrunedCommandPayload';
import type {
  CommandHistoryPayload,
  ICommandHistory,
} from './useCommandHistory';
import { historyCommandOpen, showRightPanel } from 'actions/operate/actions';
import { STATUS_SENT } from 'constants/ops/rti/oparate/constants';

export const useSendCommandHandlers = () => {
  const {
    dispatch,
    state: {
      procedureName,
      selectedCommand,
      commandCustomName,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      commandPayload,
      commandPileWorkingSpace,
      armedCommand,
      frozenPile,
      shouldFocusLastSentCommand,
      rightPanelSelectedTab,
    },
  } = useOperate();
  const {
    sendUnarmedCommand,
    addCommandToHistory,
    updateWorkspace,
    fireCommand,
  } = useCurrentTerminalSession();

  const handleSendUnarmedCommand = useCallback(
    async ({
      command,
      customName,
      payload,
      focusHistoryCmd = true,
    }: ISendUnarmedCommand & { focusHistoryCmd?: boolean }) =>
      new Promise(() => {
        const createdAt = getCurrentUtcDate();

        const { sequenceId } = sendUnarmedCommand({
          command,
          payload: getPrunedCommandPayload(payload),
          customName,
          procedureName: procedureName,
        });

        const history: ICommandHistory = {
          createdAt,
          sequenceId,
          command,
          payload,
          responsesContent: [],
          status: 'sent',
          note: '',
          subjectID: '',
          procedureName: '',
          customCommandName: '',
          satelliteSequenceID: 0,
        };

        // Show the right panel with the replies tab if the user wants to focus on the last sent command.
        // Otherwise, show the right panel with the already selected tab.
        if (shouldFocusLastSentCommand) {
          dispatch(showRightPanel('repliesInfo'));
        } else {
          dispatch(showRightPanel(rightPanelSelectedTab ?? 'repliesInfo'));
        }
        addCommandToHistory(history);

        if (focusHistoryCmd) {
          dispatch(historyCommandOpen(history, selectedCommand));
        }
      }),
    [
      sendUnarmedCommand,
      procedureName,
      shouldFocusLastSentCommand,
      addCommandToHistory,
      dispatch,
      rightPanelSelectedTab,
      selectedCommand,
    ]
  );

  /**
   * Directly send the selected command from the command editor.
   */
  const handleSendSelectedCommand = useCallback(
    async (focusHistoryCmd?: boolean) => {
      if (!selectedCommand?.name) {
        return;
      }

      await handleSendUnarmedCommand({
        command: selectedCommand.name,
        customName: commandCustomName,
        procedureName: procedureName,
        payload: getPrunedCommandPayload(
          commandPayload as CommandHistoryPayload
        ),
        focusHistoryCmd,
      });
    },
    [
      handleSendUnarmedCommand,
      commandCustomName,
      commandPayload,
      procedureName,
      selectedCommand?.name,
    ]
  );

  /**
   * Send a command from the command pile.
   */
  const handleSendPiledCommand = useCallback(
    async (
      pileItem: ICommandPileItem,
      removeItem: boolean,
      procedureNameToHandle: string,
      focusHistoryCmd?: boolean
    ) => {
      if (!commandPileWorkingSpace) {
        return;
      }

      if (removeItem) {
        const pile = commandPileWorkingSpace?.filter(
          (cpws) => cpws.id !== pileItem.id
        );
        updateWorkspace(pile, armedCommand, Boolean(frozenPile));
      }

      await handleSendUnarmedCommand({
        command: pileItem.command,
        customName: pileItem.customName,
        procedureName: procedureNameToHandle,
        payload: getPrunedCommandPayload(pileItem.payload),
        focusHistoryCmd,
      });
    },
    [
      armedCommand,
      commandPileWorkingSpace,
      frozenPile,
      handleSendUnarmedCommand,
      updateWorkspace,
    ]
  );

  const handleSendArmedCommand = useCallback(() => {
    if (!armedCommand) {
      return;
    }

    const createdAt = getCurrentUtcDate();

    const { sequenceId } = fireCommand(armedCommand);

    addCommandToHistory({
      createdAt,
      sequenceId,
      command: armedCommand.command,
      payload: commandPayload as CommandHistoryPayload,
      responsesContent: [],
      status: STATUS_SENT,
      note: '',
      subjectID: '',
      procedureName: '',
      customCommandName: '',
      satelliteSequenceID: 0,
    });
  }, [addCommandToHistory, armedCommand, commandPayload, fireCommand]);

  return {
    handleSendSelectedCommand,
    handleSendPiledCommand,
    handleSendArmedCommand,
  };
};
