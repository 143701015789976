import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import type { InputProps } from "../Input/Input";
import Input from "../Input/Input";
import Spinner from "../Spinner/Spinner";

type Props = InputProps & {
  /**
   * After how many milliseconds will the `onChange` callback fire
   */
  debounceTimeMs: number;
  inputClassName?: string;
};

/**
 * DebouncedInput represents an `<Input/>` component with a built in debouncer on the `onClick` function.
 * Shows a loading `<Spinner/>` for the duration of debounce until the `onClick` gets executed
 * Extends `InputProps`
 */
const DebouncedInput = ({
  debounceTimeMs,
  inputClassName,
  ...inputProps
}: Props) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState<boolean>(false);

  const [value, setValue] = useState<typeof inputProps.value>(
    inputProps.value ?? ""
  );

  const debouncedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value ?? "");

    const debounceFn = () => {
      setLoading(true);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        timeout.current = undefined;
        setLoading(false);
        inputProps.onChange?.(e);
      }, debounceTimeMs);
    };

    debounceFn();
  };

  useEffect(() => {
    setValue(inputProps.value ?? "");
  }, [inputProps.value]);

  return (
    <div className={classNames("relative float-left", inputProps.className)}>
      {loading && (
        <Spinner
          size={20}
          className={classNames("absolute", {
            "top-7 right-2": inputProps.label?.position === "top",
            "top-1 right-14": inputProps.label?.position === "right",
            "top-1 right-1":
              !inputProps.label || inputProps.label?.position === "left",
          })}
        />
      )}
      <Input
        {...inputProps}
        fill
        className={inputClassName}
        onChange={debouncedOnChange}
        value={value}
      ></Input>
    </div>
  );
};

export default DebouncedInput;
